import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getQuizQuestionAction} from "../actions/quizAction";
import QuizQuestionStatusContainer from "../containers/quizQuestion/quizQuestionStatusContainer";
import QuizQuestionContainer from "../containers/quizQuestion/quizQuestionContainer";

class QuizWebPage extends Component {
    question;
    isResult;
    quizIsNotActive;
    interval;
    constructor(props) {
        super(props);
        props.dispatch(getQuizQuestionAction(props.match.params, (res,quizState) => {
            if (res !== false) {
                this.isResult = quizState === "finished"
                this.question = res;
                this.quizIsNotActive = false;
                this.forceUpdate();
            } else {
                this.quizIsNotActive = true;
                this.interval = setInterval(this.checkQuizIsStarted,5000);
                this.forceUpdate();
            }
        }))
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.dispatch(getQuizQuestionAction(this.props.match.params, (res) => {
                this.question = res;
                this.forceUpdate();
            }))
        }
    }

    checkQuizIsStarted = () => {
        this.props.dispatch(getQuizQuestionAction(this.props.match.params, (res) => {
            if (res !== false) {
                this.question = res;
                this.quizIsNotActive = false;
                this.forceUpdate();
                clearInterval(this.interval);
                this.interval = null;
            }
        }))
    };

    /*goToBeforeQuestion = () => {
     let params = this.props.match.params;
     this.props.history.push(`${ROUTES.QUIZWEBTEACHER_WITHOUT_PARAMS}${params.educationSessionId}/${params.quizId}/${this.question.beforeQuestionId}`)
     };*/

    goToNextQuestion = () => {
        if (this.question.nextQuestionIsExist === true) {
            let params = this.props.match.params;
            this.props.history.push(`${ROUTES.QUIZWEB_WITHOUTPARAM}${this.props.match.params.token}/${params.educationSessionId}/${params.quizId}/${this.question.questionNumber + 1}`)
        } else {
            //TODO:RESULT SAYFASINA YÖNLEDİRELECEK.
            // this.props.history.push(`${ROUTES.QUIZRESULT_WITHOUT_PARAM}${this.props.match.params.token}/${params.educationSessionId}/${params.quizId}/${this.question.nextQuestionId}`)
            this.isResult = true;
            this.forceUpdate();
        }
    };

    render() {
        if (this.question && this.question.question) {
            this.question.question.order_number = this.question.questionNumber - 1;
        }
        return (
            <div className="mainRoot">
                {
                    this.question &&
                    <div className="quiz-web-root">

                        <div className="safe-area quiz-web-container">
                            {
                                this.isResult !== true &&
                                <QuizQuestionStatusContainer quizInfo={this.question.quizInfo}
                                                             sessionQuiz={this.question.sessionQuiz}
                                                             educationSession={this.question.educationSession}
                                                             participantSession={this.question.participantSession}
                                                             participant={this.question.participant}
                                                             quizTotalQuestionCount={this.question.quizTotalQuestionCount}
                                                             generalQuiz={this.question.generalQuiz}
                                                             quizQuestionCount={this.question.questionNumber}
                                                             educationInfo={this.question.educationInfo}
                                                             resultStatistic={this.question.resultStatistic}/>
                            }
                            {
                                this.isResult !== true ?
                                    <QuizQuestionContainer params={this.props.match.params}
                                                           question={this.question.question}
                                                           questionId={this.question.question.id}
                                                           options={this.question.options} isTeacher={false}
                                                           beforeQuestionId={this.question.beforeQuestionId}
                                                           nextQuestionId={this.question.nextQuestionId}
                                                           goToNextQuestion={this.goToNextQuestion}
                                    />
                                    :

                                    <div className=" quiz-processing-root result">
                                        <div className="desc-text-block">
                                            <div className="img-item">
                                                <img src="/assets/img/common/qrCodeGorsel.png" alt="QR"/>
                                            </div>
                                            <p className="warn-text">Quiz Sonlandı.<br/> Teşekkür ederiz.</p>
                                        </div>
                                    </div>


                            }

                        </div>
                    </div>
                }
                {
                    this.quizIsNotActive === true &&
                    <div className="quiz-web-root">
                        <div className="safe-area quiz-web-container">
                            <div className=" quiz-processing-root result">
                                <div className="desc-text-block">
                                    <div className="img-item">
                                        <img src="/assets/img/common/qrCodeGorsel.png" alt="QR"/>
                                    </div>
                                    <p className="warn-text">Quiz Daha Başlamadı.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}


export default connect()(QuizWebPage);
