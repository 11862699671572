import React, {Component} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelUserItemComponent from "../../components/Panel/panelUserItemComponent";
import InfiniteScroll from 'react-infinite-scroller';
import {getPanelLocationAction, getPanelUserAction} from "../../actions/panelAction";
import NewUserModalComponent from "../../components/modals/newUserModalComponent";
import queryString from 'query-string'
import XLSX from "xlsx";
import Helper from "../../helper";
import DatePickerInputComponent from "./datepickerComponent";
import moment from "moment";

class ReportUserListContainer extends Component {
    activePage = 0;
    order = "name_surname";
    order_type = "asc";
    isMore = true;
    isNewUser = false;
    selectedUserId;
    startDate;
    endDate = moment().format('YYYY-MM-DD');
    locationId;
    constructor(props) {
        super(props);
        this.getUsers();
        this.getLocations();
    }

    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            isReport: true,
            startDate: this.startDate,
            endDate: this.endDate,
            locationId:this.locationId
        };
        this.props.dispatch(getPanelUserAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleNewUserModal = (value, userId) => {
        this.isNewUser = value;
        this.selectedUserId = userId;
        this.forceUpdate();
    };
    getUsers = () => {
        this.activePage = 0;
        this.loadFunc();
    };
    goToDetail = (id) => {
        this.props.history.push(`${this.props.location.pathname}/${id}?endDate=${this.endDate}${this.startDate?"&startDate="+this.startDate:""}`)
    }
    downloadResultAsExcel = () => {
        let {items} = this.props;
        if (items && items[0]) {
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Eğitmen Listesi",
            };
            wb.SheetNames.push("Eğitmen Listesi");
            let sheetItems = [];
            items.map(item => {
                sheetItems.push([Helper.titleCase(item.name_surname), item.totalSessionCount, item.totalParticipantCount, "%" + Math.round(item.correct_percent)])
            })
            var ws_data = [['Kullanıcı Adı', 'Eğitim Sayısı', 'Eğitim Verdiği Katılımcı Sayısı', "Katılımcıların Başarı Sayısı"], ...sheetItems];
            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.Sheets["Eğitmen Listesi"] = ws;
            var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
            var buf = new ArrayBuffer(wbout.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
            this.saveFile(new Blob([buf], {type: "application/octet-stream"}), "_Sorular");
        }

    };

    saveFile = (blob, filename) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `egitmen_listesi.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };
    changeEndDate = (endDate) => {
        this.endDate = endDate;
        this.activePage = 0;
        this.loadFunc();
    }
    changeStartDate = (startDate) => {
        this.startDate = startDate;
        this.activePage = 0;
        this.loadFunc();
    }
    locationOnChange = (e) => {
        this.locationId = e.target.value;
        this.activePage = 0;
        this.loadFunc();
    }
    getLocations = () => {
        this.props.dispatch(getPanelLocationAction({
            activePage: 0,
            order: "name",
            order_type: "asc"}, (res, isMore) => {
        }));
    }
    render() {
        let {items,locations} = this.props;
        return (
            <div className="panel-content-block">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head table-filter">
                            <div></div>
                            {(items && items[0]) &&
                            <button className="btn-2" onClick={this.downloadResultAsExcel}>Excel İndir</button>}
                        </div>
                        <div className="table-head table-filter">
                            <div className="select-container report">
                                <div className="select-block">
                                    {
                                        locations &&
                                        <select onChange={this.locationOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                locations.map((locationItem, key) =>
                                                    <option key={key} value={locationItem.id}>{locationItem.name}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            <DatePickerInputComponent changeStartDate={this.changeStartDate}
                                                      changeEndDate={this.changeEndDate}/>
                        </div>
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("name_surname")
                            }}>
                                <p>Kullanıcı Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={() => {
                                this.sortFunc("totalSessionCount")
                            }}>
                                <p>Eğitim Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("totalParticipantCount")
                            }}>
                                <p>Eğitim Verdiği Katılımcı Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item">
                                <p>Katılımcıların Başarı Sayısı</p>
                            </div>
                        </div>
                        {
                            items &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.loadFunc}
                                hasMore={this.isMore && items.length > 19}
                                threshold={250}
                                // loader={<div className="loader" key={0}>Loading ...</div>}
                            >
                                <div className="table-block">

                                    {
                                        items && items.map((item, key) =>
                                            <PanelUserItemComponent goToDetail={this.goToDetail} isReport={true}
                                                                    toggleNewUserModal={this.toggleNewUserModal}
                                                                    key={key} {...item}/>
                                        )
                                    }
                                </div>
                            </InfiniteScroll>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.users,
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(ReportUserListContainer);
