export const LOGIN_DATA = "LOGIN_DATA";
export const GET_EDUCATION_CATEGORIES = "GET_EDUCATION_CATEGORIES";
export const GET_OPEN_EDUCATION = "GET_OPEN_EDUCATION";
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";
export const GET_EDUCATION_RESULT_BY_ID = "GET_EDUCATION_RESULT_BY_ID";
export const GET_EDUCATION_WEEKLY = "GET_EDUCATION_WEEKLY";
export const GET_FEATURE_COURSES = "GET_FEATURE_COURSES";
export const GET_FUNDAMENTAL_COURSES = "GET_FUNDAMENTAL_COURSES";
export const GET_MAIN_TILES = "GET_MAIN_TILES";
export const GET_NEWS = "GET_NEWS";
export const GET_SURVEY = "GET_SURVEY";
export const ANSWER_TO_SURVEY = "ANSWER_TO_SURVEY";
export const RECEIVE_EDUCATION_DETAIL = "RECEIVE_EDUCATION_DETAIL";
export const RECEIVE_SMART_EDUCATION_DETAIL = "RECEIVE_SMART_EDUCATION_DETAIL";
export const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS";
export const RECEIVE_SURVEYS = "RECEIVE_SURVEYS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_PANEL_FEEDBACK = "RECEIVE_PANEL_FEEDBACK";
export const RECEIVE_PANEL_EDUCATIONS = "RECEIVE_PANEL_EDUCATIONS";
export const RECEIVE_PANEL_QUIZ = "RECEIVE_PANEL_QUIZ";
export const RECEIVE_PANEL_TAG = "RECEIVE_PANEL_TAG";
export const UPDATE_PANEL_TAG = "UPDATE_PANEL_TAG";
export const RECEIVE_PANEL_DRAFT = "RECEIVE_PANEL_DRAFT";
export const RECEIVE_PARTICIPANTS = "RECEIVE_PARTICIPANTS";
export const RECEIVE_PANEL_LOCATION = "RECEIVE_PANEL_LOCATION";
export const RECEIVE_PANEL_SMART_DOCUMENTS = "RECEIVE_PANEL_SMART_DOCUMENTS";
export const RECEIVE_PANEL_NOTIFICATIONS = "RECEIVE_PANEL_NOTIFICATIONS";
export const RECEIVE_SESSION_USER = "RECEIVE_SESSION_USER";
export const RECEIVE_SMART_DOCUMENT_DETAIL = "RECEIVE_SMART_DOCUMENT_DETAIL";
export const RECEIVE_SURVEY_DETAIL = "RECEIVE_SURVEY_DETAIL";
export const RECEIVE_EDUCATION_SESSION_DETAIL = "RECEIVE_EDUCATION_SESSION_DETAIL";
export const RECEIVE_CHANGE_EDUCATION_STATUS = "RECEIVE_CHANGE_EDUCATION_STATUS";
export const UPDATE_NOTIFICATION_COUNT = "UPDATE_NOTIFICATION_COUNT";
export const RECEIVE_PANEL_PARTICIPANTS_EDUCATIONS = "RECEIVE_PANEL_PARTICIPANTS_EDUCATIONS";
export const RECEIVE_EDUCATION_PARTICIPANT_REPORT = "RECEIVE_EDUCATION_PARTICIPANT_REPORT";
export const RECEIVE_EDUCATION_BY_PARTICIPANT_REPORT = "RECEIVE_EDUCATION_BY_PARTICIPANT_REPORT";
export const RECEIVE_EDUCATION_BY_USER_REPORT = "RECEIVE_EDUCATION_BY_USER_REPORT";
