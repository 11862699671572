import React, {Component} from 'react';
import moment from 'moment';
import {endBreakSessionAction, endEducationSessionAction} from "../../actions/educationDetailAction";
import {connect} from "react-redux";
import {getOpenEducationAction} from "../../actions/index";
import {EDUCATIONDETAIL_WITHOUTPARAM, QUIZRESULT_WITHOUT_PARAM} from '../../constants/routePaths';
import {withRouter} from "react-router-dom";
import Helper from "../../helper";

class NotificationBannerComponent extends Component {
    continueSessionEducation = () => {
        this.props.dispatch(endBreakSessionAction({education_session_id:this.props.openEducation.educationSession_id},(res)=>{
            if(res === true){
                this.props.history.push(EDUCATIONDETAIL_WITHOUTPARAM+Helper.turkishToLower(this.props.openEducation.title).split(" ").join("-").split("/").join("").split("?").join("")+"-"+this.props.openEducation.education_id);
            }
        }));
    };
    endSessionEducation = () => {
        this.props.dispatch(endEducationSessionAction({id: this.props.openEducation.educationSession_id}, () => {
            this.props.dispatch(getOpenEducationAction());
        }));
    };

    goToEducationStatus = () => {
        this.props.history.push(QUIZRESULT_WITHOUT_PARAM+this.props.openEducation.educationSession_id);
    }

    render() {
        const {openEducation} = this.props;
        return (
            <div className="notification-root">
                <div className="left-block">
                    <div className="icon-item">
                        <i className="icon-screen"></i>
                        <div className="warn-item">
                            <i className="icon-triangle"></i>
                            <i className="icon-warn"></i>
                        </div>
                    </div>
                    <div className="text-item">
                        <h3>Açık eğitim seansınız var!</h3>
                        <p>{moment(openEducation.open_date).format('DD.MM.YYYY')} Tarihinde açtığınız Makineci -
                            <strong>{openEducation.title}</strong>‘ne devam edebilir, eğitimi sonlandırabilirsiniz.</p>
                    </div>
                </div>
                <div className="right-block">
                    {
                        openEducation.session_type !== "online" && <span className="btn-green" onClick={this.continueSessionEducation}>EĞİTİME DEVAM ET</span>
                    }
                    {
                        openEducation.session_type === "online" && <span className="btn-green" onClick={this.goToEducationStatus}>EĞİTİM DURUMUNU GÖR</span>
                    }
                    {
                        openEducation.session_type !== "online" && <span className="btn-2" onClick={this.endSessionEducation}>EĞİTİMİ SONLANDIR</span>
                    }

                </div>
            </div>
        )
    }

}

export default withRouter(connect()(NotificationBannerComponent));
