import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import PanelEducationItemComponent from "../../components/Panel/panelEducationItemComponent";
import {getStartedEducationList} from "../../actions/panelAction";
import InfiniteScroll from 'react-infinite-scroller';
import EducationSessionDetailModalComponent from "../../components/modals/educationSessionDetailModal";

class PanelEducationsListContainer extends Component {
    activePage = 0;
    order = "education.name";
    order_type = "asc";
    isMore = true;
    detail;
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            is_started: this.props.match.params.subPage === "tamamlanmis"|| this.props.match.params.subPage === "devam-eden",
            is_finished: this.props.match.params.subPage === "tamamlanmis"?1:0
        };
        this.props.dispatch(getStartedEducationList(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc":"asc";
        // this.order_type = this.order === sort_name ? "asc" : "desc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    toggleDetail = (detail) => {
        this.detail = detail;
        this.forceUpdate();
    };

    render() {
        let {items} = this.props;
        return (
            <Fragment>
                <div className="panel-content-block">
                    <PanelBreadCrumbComponent page="Eğitimler"/>
                    <div className="table-root">
                        <div className="table-container">
                            <div className="table-head">
                                <div className="t-1 td-item" onClick={() => {
                                    this.sortFunc("education.name")
                                }}>
                                    <p>Eğitim Adı</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                                <div className="t-2 td-item" onClick={() => {
                                    this.sortFunc("teacher")
                                }}>
                                    <p>Eğitmen Adı</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                                <div className="t-3 td-item resp-local" onClick={() => {
                                    this.sortFunc("location")
                                }}>
                                    <p>Lokasyon</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                                <div className="t-3 td-item resp-date" onClick={() => {
                                    this.sortFunc("education_start_date")
                                }}>
                                    <p>Tarihi</p>
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                    // loader={<div className="loader" key={0}>Loading ...</div>}
                                >
                                    <div className="table-block">
                                        {
                                            items && items.map((item, key) =>
                                                <PanelEducationItemComponent subPage={this.props.match.params.subPage}
                                                                             toggleDetail={this.toggleDetail} history={this.props.history}
                                                                             key={key} {...item}/>
                                            )
                                        }
                                    </div>
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.detail &&
                    <EducationSessionDetailModalComponent toggleDetail={this.toggleDetail} detail={this.detail}/>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.educations
});

export default connect(mapStateToProps)(PanelEducationsListContainer);