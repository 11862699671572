import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import {
    endEducationForParticipantAction,
    endEducationSessionAction,
    getEducationPdfAction
} from "../../actions/educationDetailAction";
import {EDITEDUCATIONDETAIL_WITHOUTPARAM, QUIZRESULT_WITHOUT_PARAM} from "../../constants/routePaths";
import VersionModalComponent from "../../components/modals/versionModalComponent";
import CloseEditModalComponent from "../../components/modals/closeEditModalComponent";
import jsPDF from 'jspdf';
import Helper from '../../helper';
import html2canvas from 'html2canvas';
import {downloadPdf} from "../../services/webService";
import {getParticipantsQrCodesAction} from "../../actions/quizAction";
import LoaderModalComponent from "../../components/common/loaderModalComponent";

class EducationDetailHeaderContainer extends Component {
    menuBtn = React.createRef();
    isModalShowing = false;
    iscloseEdit = false;
    isLoading = false;
    openLeft = () => {
        this.props.detailContent.current.classList.toggle("open-menu");
        this.menuBtn.current.classList.toggle("active");
    };
    downloadEducation = () => {
        downloadPdf('<html><head><meta charset="utf-8"><title>' + document.title + '</title>' +'<style>h2, span{display: inline-block;} img{width: 100%} div.content-text-block  {display: table-row} div.list-count  {display: table-cell;width: 50px} div.text-item  {display: table-cell;  vertical-align: middle}</style></head><body >' + this.props.detailContentComponent.current.detailContainer.current.childNodes[1].innerHTML + '</body></html>').then((res)=>{
            if(res.data.success){

            }
        })
    };
    printEducation = (elem) => {
        let myWindow = window.open('', 'PRINT', 'height=400,width=600');
        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>h2, span{display: inline-block;} img{width: 100%} div.content-text-block  {display: table-row} div.list-count  {display: table-cell;width: 50px} div.text-item  {display: table-cell;  vertical-align: middle} div.btn-item{display: none} div.img-item{display: none} span.label-tag{display: none} </style></head><body >');
        myWindow.document.write('<h1>' + document.title + '</h1>');
        myWindow.document.write(this.props.detailContentComponent.current.detailContainer.current.innerHTML);
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        myWindow.close();
        return true;
    };
    endEducation = () => {
        this.isLoading = true;
        this.forceUpdate();
        if(this.props.isOnline===true){
            this.props.dispatch(endEducationForParticipantAction({session_id:this.props.sessionId},(res)=>{
                if(res===true){
                    this.props.endSession();
                }else if(res){
                    this.props.history.push(res);
                }
            }))
        }else{
            this.props.dispatch(endEducationSessionAction({id: this.props.sessionId}, (res, result) => {
                if (res === true) {
                    this.isLoading = false;
                    this.forceUpdate();
                    this.props.history.push(QUIZRESULT_WITHOUT_PARAM + this.props.sessionId)
                    // this.props.endSession(false);
                    // this.props.openModal(true, "Eğitimi Tamamladınız!", null, true, result.isQuizExist)
                }
            }));
        }

    };
    toggleModal = (value, type) => {
        this.isModalShowing = value;
        this.type = type;
        this.forceUpdate();
    };
    toggleCloseEdit = (value) => {
        this.iscloseEdit = value;
        this.forceUpdate();
    };
    printQrCode = () => {
        this.props.dispatch(getParticipantsQrCodesAction({session_id:this.props.sessionId},(result)=>{
            if(result!==null){
                window.open(window.location.origin+"/back/"+result.filePath, "_blank");
            }
        }));
    };
    render() {
        let {isEditing, isStarted, roleId, detailInfo, isEditExist, isVersion, activeTab, changeTab, versionData, isQuizExist} = this.props;
        return (
        <Fragment>
            <div className="detail-head-root">
                {
                    (isEditExist && isVersion === true) &&
                    <div className="detail-head-block safe-area">
                        <div className="radio-container">
                            <div className="radio-block">
                                <div className="radio-item">
                                    <input id={`radio-1`} onChange={() => {
                                        changeTab("orjinal")
                                    }} checked={activeTab === "orjinal"} name="radio" type="radio"/>
                                    <label htmlFor={`radio-1`} className="radio-label">Orjinal Döküman <span> 13.08.2019 - 10:30</span></label>
                                </div>
                                <div className="radio-item">
                                    <input id={`radio-2`} onChange={() => {
                                        changeTab("version")
                                    }} checked={activeTab === "version"} name="radio" type="radio"/>
                                    <label htmlFor={`radio-2`} className="radio-label">Düzenleme
                                        Önerisi <span>{isEditExist.created_date}</span> </label>
                                </div>
                            </div>
                        </div>
                        <div className="right-block">
                            <div className="btn-block">
                                    <span className="btn-green" onClick={() => {
                                        this.toggleModal(true, "confirm")
                                    }}>KABUL ET</span>
                                <span className="btn-2" onClick={() => {
                                    this.toggleModal(true, "cancel")
                                }}>REDDET</span>
                            </div>
                        </div>
                    </div>
                }
                {
                    !(isEditExist && isVersion === true) &&
                    <div className="detail-head-block safe-area">
                        <div className="left-block">
                            <div className="left-menu-btn" ref={this.menuBtn} onClick={this.openLeft}>
                                <i className="icon-menu"></i>
                            </div>
                            {/*<div className="search-btn">
                             <i className="icon-search"></i>
                             </div>*/}
                        </div>
                        {
                            isEditing === true &&
                            <div className="right-block">
                                <div className="btn-block">
                                    <span className="btn-2" onClick={() => {
                                        this.props.toggleIsEditing(false)
                                    }}>DEĞİŞİKLİKLERİ KAYDET</span>
                                    <span className="btn-2" onClick={() => {
                                        this.props.toggleIsEditing(false)
                                    }}>KAYDETMEDEN ÇIK</span>
                                </div>
                            </div>
                        }
                        {
                            //TODO:ONLINE EDUCATION EDITED
                        }
                        {
                            (isEditing === false) &&
                            (
                                this.props.isOnline?
                                    <div className="right-block">
                                        <ul>
                                            <li onClick={() => {
                                                this.props.toggleFeedBack(true)
                                            }}>GERİ BİLDİRİM
                                            </li>
                                        </ul>
                                        {
                                            isStarted === true &&
                                            <div className="btn-block">
                                                <span className="btn-2" onClick={this.endEducation}>EĞİTİMİ SONLANDIR</span>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="right-block">
                                        <ul>
                                            {
                                                (isStarted !== true && roleId === "1") &&
                                                <li onClick={() => {
                                                    this.props.changeKeyModal("isRemovedModal", true)
                                                }}>{detailInfo && detailInfo.is_active === "1" ? "YAYINDAN KALDIR" : "YAYINA AL"}</li>
                                            }
                                            {
                                                (isStarted === true && isQuizExist===true) &&
                                                <li onClick={() => {
                                                    this.printQrCode();
                                                }}>QR KOD YAZDIR
                                                </li>
                                            }
                                            {
                                                isStarted === true &&
                                                <li onClick={() => {
                                                    this.props.toggleAddParticipants(true)
                                                }}>KATILIMCI EKLE
                                                </li>
                                            }
                                            {
                                                (isStarted !== true && (roleId === "1" || roleId === "3")) &&
                                                <li onClick={() => {
                                                    {/*this.props.toggleIsEditing(true)*/}
                                                    if(isEditExist){
                                                        {/*this.toggleCloseEdit(true);*/}
                                                        this.props.history.push(`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(detailInfo.name).split(" ").join("-").split("/").join("").split("?").join("")}-${detailInfo.id}`);
                                                    }else{
                                                        this.props.history.push(`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(detailInfo.name).split(" ").join("-").split("/").join("").split("?").join("")}-${detailInfo.id}`);
                                                    }
                                                }}>DÜZENLE
                                                </li>
                                            }
                                            {/*<li onClick={this.downloadEducation}>İNDİR</li>*/}
                                            {
                                                roleId !== "2" &&
                                                <li onClick={() => {
                                                    this.props.changeKeyModal("shareModalIsShowing", true);
                                                }}>PAYLAŞ
                                                </li>
                                            }
                                            {
                                                roleId === "1" && <li onClick={this.printEducation}>EĞİTİMİ YAZDIR</li>
                                            }
                                            {
                                                roleId !== "2" &&
                                                <li onClick={() => {
                                                    this.props.toggleFeedBack(true)
                                                }}>GERİ BİLDİRİM
                                                </li>
                                            }
                                        </ul>
                                        {
                                            isStarted === true &&
                                            <div className="btn-block">
                                    <span className="btn-2" onClick={() => {
                                        this.props.openModal(true, "Eğitim ekranından ayrılmak istediğinizden emin misiniz?", "Amet dolore pede, nemo error hic perferendis fugiat molestias iaculis, reiciendis varius sociosqu distinctio iste scelerisque, veritatis.", false)
                                    }}>ARA VER</span>
                                                <span className="btn-2" onClick={this.endEducation}>EĞİTİMİ SONLANDIR</span>
                                            </div>
                                        }
                                    </div>
                            )

                        }
                    </div>
                }
                {
                    this.isModalShowing === true &&
                    <VersionModalComponent educationId={this.props.educationId} toggleModal={this.toggleModal} versionData={versionData} urlLink={this.props.urlLink}
                                           type={this.type}/>
                }
                {
                    this.iscloseEdit === true && <CloseEditModalComponent toggleCloseEdit={this.toggleCloseEdit}/>
                }
            </div>
            {
                this.isLoading === true && <LoaderModalComponent/>
            }
        </Fragment>

        )
    }
}

export default connect(null, null, null, {withRef: true})(EducationDetailHeaderContainer);