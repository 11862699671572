import React, {Component, Fragment} from 'react';
import ReactQuill, {Mixin, Quill} from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import {addDocumentPictureAction} from "../../actions/panelAction";
import {connect} from "react-redux";
import ReactQuillComponent from "../../components/editEducationDetail/reactQuillComponent";
import EducationDocumentDescriptionItemComponent
    from "../../components/educationDetail/educationDocumentDescriptionItemComponent";
import AddSmartDocumentsModal from '../../components/modals/addSmartDocumentsModal';
import AddItemBlockComponent from "../../components/editEducationDetail/addItemBlockComponent";
import Helper from '../../helper';
import EmptyAddItemBlockComponent from "../../components/editEducationDetail/emptyAddItemBlockComponent";
import LoaderModalComponent from "../../components/common/loaderModalComponent";
import {saveEducationVideoAction} from "../../actions/educationDetailAction";


class EducationDocumentEditContainer extends Component {
    headerToolBar = [
        [{'header': 1}, {'header': 2}],
        ['clean']
    ];
    module = {
        toolbar: {
            container: [
                ['list-2', 'bold', 'italic', "underline", {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, 'clean']
            ],
            handlers: {
                "list-2": () => {
                    // console.log("1111");
                    // console.log(this.quill);
                }
            }
        }
    };
    focusedIndex;
    smartIsShowing = false;
    clickedImg;
    smartIndex;
    isLast = false;
    titleIndex;
    elementContainer = React.createRef();
    imgBtn = React.createRef();
    isRekey = false;
    addNewBtn = React.createRef();
    loaderIsShowing = false;

    componentDidMount() {
        window.addEventListener('click', this.windowClickedFunc);
    }

    windowClickedFunc = (e) => {
        if (document.getElementsByClassName("detail-content-block")[0] && document.getElementsByClassName("detail-content-block")[0].contains(e.target) === false) {
            this.focusedIndex = null;
            this.detailIndex = null;
            this.forceUpdate();
        }
        if (this.elementContainer.current && this.elementContainer.current.contains(e.target) === true) {
            if (e.target.tagName === "IMG") {
                this.clickedImg = e.target;
                this.imgBtn.current.style.display = "block";
                this.imgBtn.current.style.top = Helper.getOffset(e.target.parentNode, this.props.detailContainer.current).top + "px";
            } else {
                this.clickedImg = null;
                this.imgBtn.current.style.display = "none";
            }
        } else {
            this.clickedImg = null;
            this.imgBtn.current.style.display = "none";
        }
    };
    onKeyDown = (e, subItem, subItems, objectKey, inx, documentItem) => {
        let regex = /(<([^>]+)>)/ig;
        let key = typeof subItem.content !== "undefined" ? "content" : "text";
        if (e.keyCode === 8 && (!subItem[key] || subItem[key].replace(regex, "") === "" || subItem[key] === " ") && subItem.clear === true) {
            if (inx > -1) {
                let items = Object.assign([], subItems);
                items.splice(inx, 1);
                subItems.splice(inx, 1);
                if (documentItem.subItems) {
                    //TODO:DEĞİŞİKLİK YAPILDIĞINDA ÖNCEKİ MADDENİN ÜSTÜNE YAZIYOR!! BAKILACAK.
                    if (subItems[inx - 1]) {
                        subItems[inx - 1].content = subItems[inx - 1].content + '<p>&nbsp</p>';
                    }
                    this.updateComponent(true);
                    setTimeout(() => {
                        if (inx === 0 && this[`${objectKey}`]) {
                            this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.display = "none";
                            this[`${objectKey}`].focus();
                            Helper.focusLast(this[`${objectKey}`]);
                        } else {
                            let element = this[`${objectKey.substring(0, objectKey.lastIndexOf("."))}.${this.getLastContentIndex(documentItem.subItems)}`];
                            if (element) {
                                element.focus();
                                Helper.focusLast(element);
                            }
                        }
                    }, 150)
                } else {
                    //TODO:DEĞİŞİKLİK YAPILDIĞINDA ÖNCEKİ MADDENİN ÜSTÜNE YAZIYOR!! BAKILACAK.

                    this.updateComponent(true);
                    if (this[this.getLastContentIndex(documentItem)]) {
                        this[this.getLastContentIndex(documentItem)].focus();
                        Helper.focusLast(this[this.getLastContentIndex(documentItem)]);
                    }
                }
            }
        }
    };
    setComponent = (ref, component) => {
        this[ref] = component;
    };
    setOnFocusElement = (index, value) => {
        let openedItems = document.getElementsByClassName("add-content-block opened");
        Array.prototype.forEach.call(openedItems, function (el) {
            el.classList.remove("opened");
        });
        this.focusedIndex = index;
        this.forceUpdate();
    };
    subUploadVideo = (e, subItem, key, isDesc, cb, index, rangeIndex) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(saveEducationVideoAction(e.target.files[0], (res) => {
            this.loaderIsShowing = false;
            if (isDesc === true) {
                // this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res, "egitim");
                // this.editor.insertEmbed(rangeIndex, 'p', '<video width="500" height="500" controls=""><source
                // src="/assets//videos/education/yakaayağıkırmaütü-9662cb886681fb0fa5f4c86ad08e7b73.mp4' + res+ '"
                // type="video/mp4"></video>', "egitim");
                this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res + '?autoplay=false', "egitim");
            } else {
                // this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res, "egitim");
                // this.editor.insertEmbed(rangeIndex, 'p','<video width="500" height="500" controls=""><source
                // src="/assets//videos/education/yakaayağıkırmaütü-9662cb886681fb0fa5f4c86ad08e7b73.mp4' + res+ '"
                // type="video/mp4"></video>', "egitim");
                this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res + '?autoplay=false', "egitim");

                // this.editor.insertEmbed(rangeIndex, 'video','<iframe width="560" height="315" src="/assets/' + res +
                // '?autoplay=false" frameborder="0" allowfullscreen></iframe>', "egitim");
            }
            cb && cb();
            this[key] && this[key].classList.remove("opened");
            this.forceUpdate();
        }))
    };
    subImgOnLoad = (e, subItem, key, isDesc, cb, index, rangeIndex) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(addDocumentPictureAction(e.target.files[0], "education", (res) => {
            this.loaderIsShowing = false;
            if (res !== false) {
                if (isDesc === true) {
                    this.editor.insertEmbed(rangeIndex, 'image', '/assets/' + res, "egitim");
                } else {
                    this.editor.insertEmbed(rangeIndex, 'image', '/assets/' + res, "egitim");
                }
            } else {
                alert("Resim yüklenemedi, lütfen ilgili kişilerle iletişime geçiniz.");
            }

            cb && cb();
            this[key] && this[key].classList.remove("opened");
            this.forceUpdate();
        }))
    };
    subVideoOnLoad = (e, subItem, index, isDesc, cb, keyIndex, rangeIndex) => {
        if (e.keyCode === 13) {
            let videoId = Helper.getVideoId(e.target.value);
            if (isDesc === true) {
                /*this.editor.insertEmbed(rangeIndex, 'iframe', '<iframe width="560" height="315" src="//www.youtube.com/embed/'
                    + videoId + '" frameborder="0" allowfullscreen></iframe>');*/

                this.editor.insertEmbed(rangeIndex, 'video', '//www.youtube.com/embed/' + videoId);
            } else {
                this.editor.insertEmbed(rangeIndex, 'video', '//www.youtube.com/embed/' + videoId);
            }
            cb && cb();
            this[index] && this[index].classList.remove("opened");
            this.forceUpdate();
        }
    };
    getContent = (subItems, index, documentItem, documentItems, isReadOnly, mainObject, documentKey) => {
        let props = {
            documentItems: documentItems,
            focusElement: this.focusElement,
            setOnFocusElement: this.setOnFocusElement,
            toggleSmartDocument: this.toggleSmartDocument,
            addSubItem: this.addSubItem,
            urlLink: this.props.urlLink,
            addTitle: this.addTitle,
            documentItem: documentItem,
            onKeyDown: this.onKeyDown,
            subImgOnLoad: this.subImgOnLoad,
            updateComponent: this.updateComponent,
            subVideoOnLoad: this.subVideoOnLoad,
            subItems: subItems,
            setComponent: this.setComponent,
            addSubTitle: this.addSubTitle,
            isSmartDocuments: this.props.isSmartDocuments,
            isSmartDocumentEdit: this.props.isSmartDocumentEdit,
            isReKey: this.isRekey,
            setAddBtnPosition: this.setAddBtnPosition,
            mainObject: mainObject,
            documentKey: documentKey
        };
        return subItems ? subItems.map((subItem, key) =>
            <Fragment key={key}>
                <ReactQuillComponent isReadOnly={isReadOnly && this.props.isSmartDocumentEdit !== true} {...props}
                                     subItem={subItem} index={index} indexKey={key}/>
                {
                    (subItem.subItems && subItem.subItems.length > 0) && this.getContent(subItem.subItems, `${index}.${key + 1}`, subItem, subItems, isReadOnly, mainObject, documentKey)
                }
            </Fragment>) : <div></div>
    };
    getLastContent = (subItems) => {
        if (subItems[subItems.length - 1].subItems) {
            return this.getLastContent(subItems[subItems.length - 1].subItems)
        } else {
            return subItems[subItems.length - 1];
        }
    };
    getLastContentIndex = (subItems) => {
        if (subItems[subItems.length - 1] && subItems[subItems.length - 1].subItems) {
            return `${(subItems.length)}.${(this.getLastContentIndex(subItems[subItems.length - 1].subItems))}`
        } else {
            return (subItems.length);
        }
    };
    imageOnLoad = (e, cbFunc) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(addDocumentPictureAction(e.target.files[0], "education", (res) => {
            this.loaderIsShowing = false;
            if (res !== false) {
                if (this.props.detailDocument[this.props.detailDocument.length - 1] && this.props.detailDocument[this.props.detailDocument.length - 1].subItems && this.props.detailDocument[this.props.detailDocument.length - 1].subItems.length > 0) {
                    this.getLastContent(this.props.detailDocument[this.props.detailDocument.length - 1].subItems).content += `<p class="test"><img src=/assets/${res} alt=""/></p>`;
                } else {
                    this.props.detailDocument[this.props.detailDocument.length - 1].description += `<p class="test"><img src=/assets/${res} alt=""/></p>`;
                }
            } else {
                alert("Resim yüklenemedi. Lütfen ilgili kişiyle iletişime geçiniz.");
            }
            cbFunc && cbFunc();
            this.forceUpdate();
        }));
    };
    videoOnLoad = (e, cbFunc) => {
        this.loaderIsShowing = true;
        this.forceUpdate();
        this.props.dispatch(saveEducationVideoAction(e.target.files[0], (res) => {
            this.loaderIsShowing = false;
            if (res !== false) {
                if (this.props.detailDocument[this.props.detailDocument.length - 1] && this.props.detailDocument[this.props.detailDocument.length - 1].subItems && this.props.detailDocument[this.props.detailDocument.length - 1].subItems.length > 0) {
                    // this.getLastContent(this.props.detailDocument[this.props.detailDocument.length -
                    // 1].subItems).content += `<video controls="true" width="250"><source src=/assets${res}
                    // type="video/mp4"></video>`;
                    this.getLastContent(this.props.detailDocument[this.props.detailDocument.length - 1].subItems).content += `<p><iframe class="ql-video" src=/assets${res} frameborder="0" allowfullscreen></iframe></p>`;
                    // this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res, "egitim");
                } else {
                    // this.editor.insertEmbed(rangeIndex, 'video', '/assets/' + res, "egitim");
                    this.props.detailDocument[this.props.detailDocument.length - 1].description += `<p><iframe class="ql-video" src=/assets${res} frameborder="0" allowfullscreen></iframe></p>`;
                    // this.props.detailDocument[this.props.detailDocument.length - 1].description += `<p><img
                    // src="/asd.jpg"></p>`; this.props.detailDocument[this.props.detailDocument.length -
                    // 1].description += 11111;
                }
            } else {
                alert("Video yüklenemedi. Lütfen ilgili kişiyle iletişime geçiniz.");
            }
            cbFunc && cbFunc();
            this.forceUpdate();
        }));
    };
    textOnKeyUp = (e, cb) => {
        if (e.keyCode === 13) {
            let videoId = Helper.getVideoId(e.target.value);
            if (this.props.detailDocument[this.props.detailDocument.length - 1].subItems && this.props.detailDocument[this.props.detailDocument.length - 1].subItems.length > 0) {
                this.getLastContent(this.props.detailDocument[this.props.detailDocument.length - 1].subItems).content += '<iframe width="560" height="315" src="//www.youtube.com/embed/'
                    + videoId + '" frameborder="0" allowfullscreen></iframe>';
            } else {
                this.props.detailDocument[this.props.detailDocument.length - 1].description += '<iframe width="560" height="315" src="//www.youtube.com/embed/'
                    + videoId + '" frameborder="0" allowfullscreen></iframe>';
            }
            cb && cb();
        }
    };
    addTitle = (index) => {
        let urlArr = this.props.urlLink.split("-");
        let newObj = {
            education_id: urlArr[urlArr.length - 1],
            text: " ",
            order_number: this.props.detailDocument.length + 1
        };
        if (typeof index === "number") {
            this.props.detailDocument.splice(index + 1, 0, newObj);
            this.updateComponent(true);
        } else {
            this.props.detailDocument.push(newObj);
        }
        this.forceUpdate();
    };
    addSubItem = (documentItem, index, subKey, value, isReturn) => {
        //TODO:DEĞİŞİKLİK YAPILDIĞINDA ÖNCEKİ MADDENİN ÜSTÜNE YAZIYOR!! BAKILACAK.
        let urlArr = this.props.urlLink.split("-");
        let newObj = {
            education_id: urlArr[urlArr.length - 1],
            content: value ? value.toString() : "",
            heading_id: documentItem.id,
            order_number: 1,
            ref_id: null
        };
        documentItem.subItems = documentItem.subItems ? documentItem.subItems : [];
        documentItem.subItems = [
            ...documentItem.subItems.slice(0, subKey + 1),
            newObj,
            ...documentItem.subItems.slice(subKey + 1)
        ];
        this.updateComponent(true);
        setTimeout(() => {
            this[`${index}.${subKey + (isReturn === true ? 1 : 2)}`].focus();
        }, 150);
        /* let newSubItems = [
         ...documentItem.subItems.slice(0, subKey + 1),
         newObj,
         ...documentItem.subItems.slice(subKey + 1)
         ];
         documentItem.subItems = [];*/
        /*this.forceUpdate();
         setTimeout(() => {
         documentItem.subItems = newSubItems;
         this.forceUpdate();
         this[`${index}.${subKey + (isReturn === true ? 1 : 2)}`].focus();
         }, 1);*/
    };
    updateComponent = (value) => {
        this.isRekey = value;
        this.forceUpdate();
        setTimeout(() => {
            this.isRekey = false;
            this.forceUpdate();
        }, 100)
    };
    focusElement = (key) => {
        setTimeout(() => {
            this[key] && this[key].focus();
        }, 1);
    };
    toggleSmartDocument = (value, initIndex) => {
        this.smartIsShowing = value;
        this.smartIndex = initIndex;
        this.forceUpdate();
    };
    deleteDescription = (value, index, deletedDescText, descText) => {
        value.description = "";
        // this.focusedIndex = index + 1;
        this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.display = "none";
        this[`${index + 1}`].focus();
        Helper.focusLast(this[`${index + 1}`]);
        this.forceUpdate();
    };
    changeImgClass = (className) => {
        this.clickedImg.className = "";
        if (className !== "") {
            this.clickedImg.classList.add(className);
        }
    };
    addSmartDocument = (item, index) => {
        let lastArr = index ? this.props.detailDocument.splice(index + 1) : this.props.detailDocument;
        if (index) {
            this.props.detailDocument.slice(0, index);
        }
        item.content.map((content) => {
            content.smart_document_id = item.id;
            this.props.detailDocument.push(content);
        });
        index && lastArr.map((item) => {
            this.props.detailDocument.push(item);
        });
        this.smartIsShowing = false;
        let openedItems = document.getElementsByClassName("add-content-block opened");
        Array.prototype.forEach.call(openedItems, function (el) {
            el.classList.remove("opened");
        });
        this.updateComponent(true);
    };

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.detailDocument && this.props.detailDocument.length === 0) {
            this.addTitle();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.windowClickedFunc);
    }

    addDescription = (documentItem, key) => {
        documentItem.description = documentItem.description ? documentItem.description : " ";
        this.forceUpdate();
        setTimeout(() => {
            this[`${key + 1}.desc`] && this[`${key + 1}.desc`].focus();
        }, 50);
    };
    setAddBtnPosition = (element, isLast, titleIndex, documentItem, keyString, indexKey, isDesc, editor) => {
        this.isDesc = isDesc;
        let range = element.editor.getSelection();
        if (!range) {
            return;
        }
        range.length = 1;
        this.editor = editor;
        if (element.editor.getLines(range)[0] && element.editor.getLines(range)[0].domNode.innerText.trim().length === 0) {
            this.isLast = isLast;
            this.titleIndex = titleIndex;
            this.documentItem = documentItem;
            this.keyString = keyString;
            this.indexKey = indexKey;
            this.rangeIndex = range.index;
            this.addNewBtn.current.getWrappedInstance().videoLinkIsShowing = false;
            this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.display = "flex";
            this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.position = "absolute";
            this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.top = Helper.getOffset(element.editor.getLines(range)[0].domNode, this.props.detailContainer.current).top - 15 + "px";
        } else {
            this.addNewBtn.current.getWrappedInstance().videoLinkIsShowing = false;
            this.isLast = false;
            this.titleIndex = null;
            this.addNewBtn.current.getWrappedInstance().addNewBtn.current.style.display = "none";
        }
    };
    toggleEdit = (index) => {
        this.detailIndex = index;
        this.forceUpdate();
    };
    removeTitle = (key, document) => {
        if (document.smart_documents_id || document.smart_document_id) {
            let smartValue = document.smart_documents_id || document.smart_document_id;
            let length = this.props.detailDocument.filter(item => item.smart_document_id === smartValue || item.smart_documents_id === smartValue).length;
            this.props.detailDocument.splice(key, length);
        } else {
            this.props.detailDocument.splice(key, 1);
        }
        this.detailIndex = null;
        this.updateComponent(true);
    };

    render() {
        let {detailDocument, isSmartDocuments, isSmartDocumentEdit} = this.props;
        return (
            <div ref={this.elementContainer}>
                {
                    detailDocument && detailDocument.map((documentItem, key) =>
                        <Fragment key={key}>
                            <div
                                className={"content-text-block edit-text-contetn " + (documentItem.smart_document_id || documentItem.smart_documents_id ? "smart" : "")}>
                                <div
                                    className={"list-count head-count" + (this.focusedIndex === key + 1 ? " add-head" : "")}>
                                    {/*<AddItemBlockComponent isSmartDocuments={isSmartDocuments}
                                     imageOnLoad={(e, cb) => {
                                     this.subImgOnLoad(e, documentItem, ("content-block" + `${key + 1}`), true, cb, key + 1)
                                     }}
                                     index={key}
                                     addTitle={this.addTitle}
                                     // isFocused={this.focusedIndex === key + 1}
                                     isLastEdit={false}
                                     addDelimeter={() => {
                                     documentItem.description += '<div><img src=""/></div>';
                                     this.forceUpdate();
                                     }}
                                     addSmartDoc={() => {
                                     this.toggleSmartDocument(true);
                                     }}
                                     textOnKeyUp={(e, cb) => {
                                     this.subVideoOnLoad(e, documentItem, "content-block" + `${key + 1}`, true, cb);
                                     }}/>*/}

                                    {
                                        (detailDocument.filter(item => item.smart_document_id === documentItem.smart_document_id || item.smart_documents_id === documentItem.smart_documents_id).findIndex(item => item.text === documentItem.text) === 0 || (!documentItem.smart_document_id && !documentItem.smart_documents_id)) ?

                                            <div
                                                className={"edit-action-block " + (this.detailIndex === key ? "open" : "")}>
                                                <div className="edit-action-item"
                                                     onClick={() => {
                                                         this.toggleEdit(this.detailIndex === key ? null : key)
                                                     }}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                                <div className="open-action-block">
                                                    <div className="arr-up"></div>
                                                    <ul>
                                                        <li onClick={() => {
                                                            this.removeTitle(key, documentItem)
                                                        }}>İçeriği sil
                                                        </li>
                                                        {/*<li>Geri Bildirim</li>*/}
                                                        {/*<li>Lorem ipsum</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                            :
                                            <div
                                                className={"edit-action-block " + (this.detailIndex === key ? "open" : "")}>
                                                <div className="edit-action-item"></div>
                                                <div className="open-action-block"></div>
                                            </div>
                                    }
                                    <span>{key + 1}</span>

                                </div>
                                <div className="text-item">
                                    <div>
                                        <ReactQuill value={documentItem.text || ' '} theme="bubble"
                                                    key={!this.isRekey || Helper.generateString(10)}
                                                    modules={{toolbar: this.headerToolBar}}
                                                    scrollingContainer={document.documentElement}
                                                    readOnly={!!documentItem.smart_documents_id && isSmartDocumentEdit !== true}
                                                    ref={(component) => {
                                                        this[`${key + 1}`] = component
                                                    }}
                                                    onFocus={() => {
                                                        this.setOnFocusElement(key + 1);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            this.addDescription(documentItem, key);
                                                        } else {
                                                            let newDocumentItem = Object.assign({}, documentItem);
                                                            let subItemKey = detailDocument[key - 1] && detailDocument[key - 1].subItems ? detailDocument[key - 1].subItems.length : 0;
                                                            let objectKey = `${key}.${subItemKey}`;
                                                            this.onKeyDown(e, newDocumentItem, detailDocument, objectKey, key, detailDocument);
                                                            let regex = /(<([^>]+)>)/ig;
                                                            documentItem.clear = (!documentItem.text || documentItem.text.indexOf("img") === -1) && !documentItem.text || documentItem.text.replace(regex, "") === "" || documentItem.text === " ";
                                                        }
                                                    }}
                                                    onChange={(value) => {
                                                        documentItem.text = value.replace("<p><br></p>", "");
                                                        this.forceUpdate();
                                                    }}/>
                                    </div>
                                </div>
                            </div>
                            {
                                documentItem.description &&
                                <div
                                    className={"content-text-block " + (documentItem.smart_document_id ? "smart" : "")}>
                                    <div className="list-count"></div>
                                    <div className="text-item">
                                        <EducationDocumentDescriptionItemComponent
                                            documentItem={documentItem}
                                            indexKey={key}
                                            ref={(component) => {
                                                this[`${key + 1}-desc`] = component;
                                            }}
                                            setAddBtnPosition={this.setAddBtnPosition}
                                            isSmartDocumentEdit={isSmartDocumentEdit}
                                            deleteDescription={this.deleteDescription}
                                            setOnFocusElement={this.setOnFocusElement}
                                            setComponent={this.setComponent}
                                            updateComponent={this.updateComponent}
                                            addSubItem={this.addSubItem}
                                            keyString={"content-block" + `${key + 1}`}
                                        />
                                    </div>
                                </div>
                            }
                            {this.getContent(documentItem.subItems, key + 1, documentItem, detailDocument, !!documentItem.smart_documents_id, documentItem, key)}

                        </Fragment>)
                }
                <AddItemBlockComponent imageOnLoad={this.imageOnLoad} addTitle={this.addTitle} isLastEdit={true}
                                       videoOnLoad={this.videoOnLoad}
                                       isSmartDocuments={isSmartDocuments} addSmartDocument={this.addSmartDocument}
                                       uploadVideo={this.videoOnLoad}
                                       textOnKeyUp={this.textOnKeyUp} addSmartDoc={() => {
                    this.toggleSmartDocument(true, detailDocument.length)
                }}/>
                {
                    this.smartIsShowing === true && <AddSmartDocumentsModal addSmartDocument={this.addSmartDocument}
                                                                            toggleModal={this.toggleSmartDocument}
                                                                            smartIndex={this.smartIndex}/>
                }
                <div style={{display: "none", position: "absolute"}} ref={this.imgBtn}>
                    <span onClick={() => {
                        this.changeImgClass("")
                    }}>1</span>
                    <span onClick={() => {
                        this.changeImgClass("right")
                    }}>2</span>
                    <span onClick={() => {
                        this.changeImgClass("left")
                    }}>3</span>

                </div>

                <EmptyAddItemBlockComponent addSmartDocument={this.addSmartDocument} titleIndex={this.titleIndex}
                                            imageOnLoad={(e, cb) => {
                                                this.subImgOnLoad(e, this.documentItem, this.keyString, this.isDesc, cb, this.keyIndex, this.rangeIndex)
                                            }}
                                            uploadVideo={(e, cb) => {
                                                this.subUploadVideo(e, this.documentItem, this.keyString, this.isDesc, cb, this.keyIndex, this.rangeIndex)
                                            }}
                                            textOnKeyUp={(e, cb) => {
                                                this.subVideoOnLoad(e, this.documentItem, this.keyString, this.isDesc, cb, this.keyIndex, this.rangeIndex)
                                            }}
                                            videoOnLoad={(e, cb) => {
                                                this.videoOnLoad()
                                            }}
                                            addTitle={this.addTitle} isLast={this.isLast} ref={this.addNewBtn}/>
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
            </div>
        )
    }
}
;

export default connect()(EducationDocumentEditContainer);