import React from 'react';

const QuizResultListItemComponent = ({info,correct_count,total_count,isContinue}) => (
    <div className="item">
        <span>{info.participant?info.participant.name_surname:info.name_surname}</span>
        {
            !isContinue && <span className="count">{correct_count} / {total_count}</span>
        }
        {
            isContinue && <span className="count">{info.started_date?"Başladı":"Başlamadı"}</span>
        }
    </div>
);

export default QuizResultListItemComponent;