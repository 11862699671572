import {
    answerQuestion, createNewSessionQuiz, endQuiz,
    getParticipantsQrCodes, getQuizProcess,
    getQuizQuestion, getQuizResults,
    getTeacherQuizQuestion, startQuiz,
    teacherAnswerQuestion
} from "../services/webService";
import {createQuiz, getQuiz, uploadQuizQuestionImage} from "../services/adminService";

export const getParticipantsQrCodesAction = (params,cb) => dispatch => {
    getParticipantsQrCodes(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(null)
        }
    }).catch((err)=>{
        cb(null)
    })
};

export const getQuizQuestionAction = (params,cb) => dispatch => {
    getQuizQuestion(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success,res.data.state)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
};

export const getTeacherQuizQuestionAction = (params,cb) => dispatch => {
    getTeacherQuizQuestion(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(null)
        }
    }).catch((err)=>{
        cb(null)
    })
};

export const answerQuestionAction = (params,cb) => dispatch => {
    answerQuestion(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false,err)
    })
};

export const teacherAnswerQuestionAction = (params,cb) => dispatch => {
    teacherAnswerQuestion(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false,err)
    })
};

export const getQuizProcessAction = (params,cb) => dispatch => {
    getQuizProcess(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false,err)
    })
};


export const createNewSessionQuizAction = (params,cb) => dispatch => {
    createNewSessionQuiz(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(null)
        }
    }).catch((err)=>{
        cb(null)
    })
};

export const endQuizAction = (params,cb) => dispatch => {
    endQuiz(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(null)
        }
    }).catch((err)=>{
        cb(null)
    })
};

export const getQuizResultsAction = (params,cb) => dispatch => {
    getQuizResults(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(null)
        }
    }).catch((err)=>{
        cb(null)
    })
};

export const uploadQuizQuestionImageAction = (file,cb) => dispatch => {
    uploadQuizQuestionImage(file,"quiz").then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
};

export const createQuizAction = (params,cb) => dispatch => {
    createQuiz(params).then((res)=>{
        if(res.data.success){
            cb(true,res.data.success);
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
       cb(false,err)
    });
};

export const getQuizAction = (params,cb) => dispatch => {
    getQuiz(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success);
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false,err)
    });
};

export const startQuizAction = (params,cb) => dispatch => {
    startQuiz(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success);
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false,err)
    });
};