import React, {Component} from 'react';
import InfiniteScroll from "react-infinite-scroller";
import {connect} from "react-redux";
import {getEducationParticipantsAction} from "../../actions/reportAction";
import EducationParticipantItemComponent from "./educationParticipantItemComponent";
import XLSX from "xlsx";
import Helper from "../../helper";
import DatePickerInputComponent from "./datepickerComponent";
import moment from "moment";
import {getPanelLocationAction} from "../../actions/panelAction";

class ParticipantListComponent extends Component {
    activePage = 0;
    order = "name_surname";
    order_type = "asc";
    isMore = true;
    categoryId = "0";
    textInput = React.createRef();
    startDate;
    endDate = moment().format('YYYY-MM-DD');
    locationId;
    constructor(props) {
        super(props);
        this.loadFunc();
        this.getLocations();

    }

    loadFunc = (isFull, cb) => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            searchTerm: this.textInput.current ? this.textInput.current.value : '',
            isFull: isFull === true,
            startDate: this.startDate,
            endDate: this.endDate,
            locationId: this.locationId
        };
        this.isMore = false;
        this.forceUpdate();
        this.props.dispatch(getEducationParticipantsAction(params, (res, isMore,totalSessionTime) => {
            if (isFull === true) {
                cb && cb(res,totalSessionTime)
                this.isMore = true;
                this.forceUpdate();
            } else if (res === true) {
                this.isMore = isMore;
                this.activePage = this.activePage+1;
                this.forceUpdate();
            }else{
                this.activePage = this.activePage+1;
                this.isMore = false;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    inputKeyDown = (e) => {
        this.activePage = 0;
        this.loadFunc();
    }
    downloadResultAsExcel = () => {
        this.loadFunc(true, (items,totalSessionTime) => {
            if (items && items[0]) {
                let totalTime = 0;
                var wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Kullanıcı Listesi",
                };
                wb.SheetNames.push("Kullanıcı Listesi");
                let sheetItems = [];
                let sessionIds = [];
                items.map((item,key) => {
                    let educationNames = [];
                    let educationTeachers = [];
                    let educationDates = [];
                    if(item.educations){
                        item.educations.map((sessionItem)=>{
                            educationNames.push(sessionItem.education.name);
                            educationTeachers.push(sessionItem.teacher_name_surname);
                            educationDates.push(sessionItem.session_start_date);
                        })
                    }
                    totalTime=totalTime+((item.endTime-item.startTime));
                    let timeDiff = item.endTime-item.startTime;
                    sheetItems.push([item.name_surname, item.totalSessionCount, "%" + Math.round(item.percent),`${((item.endTime-item.startTime)/60/60).toFixed(2).toString().replace(".",",")}`,`${Math.floor(timeDiff/60/60)>10?Math.floor(timeDiff/60/60):"0"+Math.floor(timeDiff/60/60)}:${Math.floor((timeDiff/60)%60)>10?Math.floor((timeDiff/60)%60):"0"+Math.floor((timeDiff/60)%60)}`,educationNames.join(','),educationTeachers.join(','),educationDates.join(',')])
                    if(key===items.length-1){
                        sheetItems.push(["","","",""])
                        sheetItems.push([" "," ","Toplam Süre",(totalTime/60/60).toFixed(2),`${Math.floor(totalTime/60/60)>10?Math.floor(totalTime/60/60):"0"+Math.floor(totalTime/60/60)}:${Math.floor((totalTime/60)%60)>10?Math.floor((totalTime/60)%60):"0"+Math.floor((totalTime/60)%60)}`])
                        sheetItems.push([" "," ","Eğitim Bazlı Toplam Süre",(totalSessionTime/60/60).toFixed(2),`${Math.floor(totalSessionTime/60/60)>10?Math.floor(totalSessionTime/60/60):"0"+Math.floor(totalSessionTime/60/60)}:${Math.floor((totalSessionTime/60)%60)>10?Math.floor((totalSessionTime/60)%60):"0"+Math.floor((totalSessionTime/60)%60)}`])
                    }
                })
                var ws_data = [['Kullanıcı Adı', 'Katıldığı Eğitim Sayısı', 'Başarı Yüzdesi',"Eğitim Aldığı Toplam Süre (saat)","Eğitim Aldığı Toplam Süre (saat:dakika)","Eğitim Adları",'Eğitimi Verenler','Eğitim Tarihleri'], ...sheetItems];
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets["Kullanıcı Listesi"] = ws;
                var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
                var buf = new ArrayBuffer(wbout.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
                Helper.saveFile(new Blob([buf], {type: "application/octet-stream"}), `kullanici_listesi`);
            }
        })
    };
    changeEndDate = (endDate) => {
        this.endDate = endDate;
        this.activePage = 0;
        this.loadFunc();
    }
    changeStartDate = (startDate) => {
        this.startDate = startDate;
        this.activePage = 0;
        this.loadFunc();
    }
    locationOnChange = (e) => {
        this.locationId = e.target.value;
        this.activePage = 0;
        this.loadFunc();
    }
    getLocations = () => {
        this.props.dispatch(getPanelLocationAction({
            activePage: 0,
            order: "name",
            order_type: "asc"}, (res, isMore) => {
        }));
    }
    render() {
        let {items,locations} = this.props;
        return (
            <div className="panel-content-block">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head table-filter">
                            <div className="input-item">
                                <input type="text" ref={this.textInput} onKeyUp={this.inputKeyDown}/>
                            </div>
                            {(items && items[0]) &&
                            <button className="btn-2" onClick={this.downloadResultAsExcel}>Excel İndir</button>}
                        </div>
                        <div className="table-head table-filter">
                            <div className="select-container report">
                                <div className="select-block">
                                    {
                                        locations &&
                                        <select onChange={this.locationOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                locations.map((locationItem, key) =>
                                                    <option key={key} value={locationItem.id}>{locationItem.name}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            <DatePickerInputComponent changeStartDate={this.changeStartDate}
                                                      changeEndDate={this.changeEndDate}/>
                        </div>
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("name_surname")
                            }}>
                                <p>Kullanıcı Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("totalSessionCount")
                            }}>
                                <p>Katıldığı Eğitim Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                            }}>
                                <p>Başarı Yüzdesi</p>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                            }}>
                                <p>Eğitim Aldığı Toplam Süre (saat)</p>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item, key) =>
                                            <EducationParticipantItemComponent
                                                key={key}
                                                endDate={this.endDate}
                                                startDate={this.startDate}
                                                pathname={this.props.location.pathname}
                                                history={this.props.history} {...this.props} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.education_participant_report,
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(ParticipantListComponent);
