import React, {Component} from 'react'
import ReactQuill, {Mixin, Quill} from 'react-quill';
import Helper from '../../helper';


const Video = Quill.import('formats/video');
const Link = Quill.import('formats/link');

class CoustomVideo extends Video {
    static create(value) {
        if(value && value.indexOf("embed")===-1){
            const node = super.create(value);
            const video = document.createElement('video')
            video.setAttribute('controls', true);
            video.setAttribute('type', "video/mp4");
            video.setAttribute('style', "height: 400px; width: 100%");
            video.setAttribute('src', this.sanitize(value));
            node.appendChild(video);
            return node;
        }else{
            const node = super.create(value.replace("#IFRAME#",""));
            const video = document.createElement('iframe');
            video.setAttribute('controls', true);
            video.setAttribute('style', "height: 400px; width: 100%");
            video.setAttribute('src', this.sanitize(value.replace("#IFRAME#","")));
            node.appendChild(video);
            return node;
        }

    }

    static sanitize(url) {
        return Link.sanitize(url);
    }
};
CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'DIV';

Quill.register('formats/video', CoustomVideo);

class EducationDocumentDescriptionItemComponent extends Component {
    editedComponent;
    isClear;
    module = {
        toolbar: {
            container: [
                ['list-2',{'list': 'bullet'},{'indent': '-1'}, {'indent': '+1'},'bold', 'italic',"underline", {'header': 2}, 'link','clean']
            ],
            handlers: {
                "list-2": () => {
                    let range = this.editedComponent.editor.getSelection();
                    if (range === null || range.length === 0) return;
                    let text = "";
                    this.editedComponent.editor.getLines(range).map((element,index)=>{
                        text += `<p>${element.domNode.innerHTML}</p>`;
                    });
                    // let text = this.editedComponent.editor.getLines(range)[0].domNode.innerHTML;
                    let oldText = this.props.documentItem.description;
                    oldText = oldText.replace(text, "");
                    this.props.addSubItem(this.props.documentItem, `${this.props.indexKey + 1}`, -1, text);
                    this.props.documentItem.description = oldText;
                    // this.editedComponent.editor.setText(oldText);
                }
            }
        }
    };
    quilKey = Helper.generateString(10);
    onSelectionChange = () => {
        if(this.editedComponent){
            let isLast = false;
            let range = this.editedComponent.editor.getSelection();
            let regex = /(<([^>]+)>)/ig;
            if(range && (!this.props.documentItem.subItems || this.props.documentItem.subItems.length === 0) && this.props.documentItem.description.trim().replace(regex,"").length <= range.index){
                isLast = true;
            }
            this.props.setAddBtnPosition(this.editedComponent,isLast,this.props.indexKey,this.props.documentItem,this.props.keyString,this.props.indexKey+1,true,this.editedComponent.editor);
        }
    };
    render() {
        let {documentItem, setComponent, updateComponent, indexKey, setOnFocusElement, deleteDescription, isSmartDocumentEdit, setAddBtnPosition} = this.props;
        return (
            <ReactQuill value={documentItem.description}
                        ref={(component) => {
                            this.editedComponent = component;
                            setComponent(`${indexKey + 1}.desc`, component);
                        }}
                        key={this.quilKey}
                        theme="bubble"
                        onChangeSelection={this.onSelectionChange}
                        scrollingContainer={document.documentElement}
                        readOnly={!!documentItem.smart_documents_id && isSmartDocumentEdit !== true}
                        modules={this.module}
                        onChange={(value, delta, source, editor) => {
                            documentItem.description = value;
                            updateComponent();
                        }}
                        onKeyUp={(e) => {

                            let regex = /(<([^>]+)>)/ig;
                            let range = this.editedComponent.editor.getSelection();

                            if (e.keyCode === 8 && this.isClear === true) {
                                deleteDescription(documentItem, indexKey);
                            } else {
                                this.isClear = documentItem.description.indexOf("img")===-1 && documentItem.description.replace(regex, "").trim().length === 0;
                                this.forceUpdate();
                            }
                        }}
                        onFocus={() => {
                            setOnFocusElement(indexKey + 1,documentItem.description)
                        }}
            />
        )
    }
};

export default EducationDocumentDescriptionItemComponent;