import React from 'react';
import {connect} from "react-redux";

const ModalEducationInfoComponent = ({detailInfo, setActiveTab,activeTab,isParticipantModal}) => (
    <div className="head-block">
        <h2>{detailInfo.name}</h2>
        <div className="info-item">
            <span className="label-tag green">#{detailInfo.tag.toLocaleUpperCase('tr')}</span>
            <div className="time-block">
                <span><i className="icon-clock"></i>{detailInfo.duration}</span>
            </div>
        </div>
        {
            //TODO:ONLINE EDUCATION EDITED
        }
        {
            isParticipantModal &&
            <div className="select-input-block">
                <div className="radio-block">
                    <div className="radio-item">
                        <input type="radio"  onClick={()=>{
                            setActiveTab("lokasyon")
                        }} className={(activeTab==="lokasyon"?"active":"")}/>
                        <label  className={"radio-label "}>TOPLU EĞİTİM</label>
                    </div>
                    <div className="radio-item">
                        <input type="radio" onClick={()=>{
                            setActiveTab("online")
                        }} className={(activeTab==="online"?"active":"")}/>
                        <label className={"radio-label"}>BİREYSEL EĞİTİM</label>
                    </div>

                </div>
            </div>
        }



    </div>
);

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    return({
    detailInfo: state.educationDetailReducer[urlArr[urlArr.length-1]] ? state.educationDetailReducer[urlArr[urlArr.length-1]].info : null
});};

export default connect(mapStateToProps)(ModalEducationInfoComponent);