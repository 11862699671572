import React,{Component} from 'react';
import {Link} from "react-router-dom";
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import {ASSETS} from "../../constants/Path";
import {changeBannerItemAction, educationAutoCompleteAction} from "../../actions/panelAction";
import {connect} from "react-redux";
import Helper from '../../helper';

class MainBannerComponent extends Component{
    editContent = React.createRef();
    searchInput = React.createRef();
    componentDidMount() {
        if(typeof window !== "undefined"){
            window.addEventListener('click',this.windowClicked);
        }
    }

    windowClicked = (e) => {
        if(this.editContent.current && this.editContent.current.contains(e.target)===false){
            this.editContent.current.classList.remove("open");
        }
    };

    componentWillUnmount() {
        if(typeof window !== "undefined"){
            window.addEventListener('click',this.windowClicked);
        }
    }

    toggleEdit = () => {
        this.editContent.current.classList.toggle("open");
    };
    searchEducation = () => {
        if(this.searchInput.current.value.length>0){
            this.props.dispatch(educationAutoCompleteAction({searchTerm:this.searchInput.current.value},(res)=>{
                if(res){
                    this.resultArr = res;
                    this.forceUpdate();
                }else{
                    this.resultArr = [];
                    this.forceUpdate();
                }
            }))
        }
    };

    changeItem = (resultItem) => {
        let params = {education_id:resultItem.id,order_number:this.props.index};
        this.props.dispatch(changeBannerItemAction(params,(res)=>{
            if(res){
                this.resultArr = [];
                this.editContent.current.classList.remove("open");
                this.props.refreshData();
            }
        }));
    };
    render(){
        let {main,roleType} = this.props;
        return(
            <div className="box-block">
                <div className="box-item">
                    {
                        roleType === "1" &&
                        <div className="edit-content-root" ref={this.editContent}>
                            <div className="edit-btn">
                                <i className="icon-pen" onClick={this.toggleEdit}></i>
                                <i className="icon-close" onClick={this.toggleEdit}></i>
                            </div>

                            <div className="open-search-content">
                                <div className="search-item">
                                    <i className="icon-search"></i>
                                    <input type="text" ref={this.searchInput} onChange={this.searchEducation} placeholder=""/>
                                </div>
                                <div className="autocomplete-root">
                                    <ul>
                                        {
                                            this.resultArr && this.resultArr.map((resultItem,key)=>
                                                <li key={key} onClick={()=>{this.changeItem(resultItem)}}><i className="icon-doc"></i> {resultItem.name}</li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="img-item">
                        <img src={main.education?ASSETS+main.education.image:"/assets/img/education/dikimegitimi.png"} alt=""/>
                    </div>
                    {
                        main && main.education ?
                            <div className="info-block">
                                <span className={`label-tag ${main.educationCategory.tag?"green":"blue"}`}>{main.educationCategory.tag?main.educationCategory.tag:"DUYURU"}</span>
                                <Link
                                    to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(main.education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${main.education.id}`}>
                                    <h3>{Helper.titleCase(main.education.name)}</h3>
                                </Link>
                                {
                                    main.education.duration &&
                                    <div className="time-block">
                                        <span><i className="icon-clock"></i>{main.education.duration}</span>
                                    </div>
                                }
                                <Link
                                    to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(main.education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${main.education.id}`}>
                                    <p>{main.description}</p>
                                </Link>
                                <div className="btn-item">
                                    <Link
                                        to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(main.education.name).split(" ").join("-").split("/").join("").split("?").join("")}-${main.education.id}`}
                                        className="link-btn wh">EĞİTİMİ İNCELE</Link>
                                </div>
                            </div>
                            :
                            <div className="info-block">
                                <span className={`label-tag ${main.educationCategory.tag?"green":"blue"}`}>{main.educationCategory.tag?main.educationCategory.tag:"DUYURU"}</span>
                                <Link to={`${main.link}` }>
                                    <h3>{main.announcemen_text}</h3>
                                </Link>
                                {/*<div className="time-block">
                                 <span><i className="icon-clock"></i>{mainTiles.main.duration}</span>
                                 </div>*/}
                                {/*<Link to={ROUTES.EDUCATIONDETAIL}><p>{mainTiles.main.description}</p></Link>*/}

                                <div className="btn-item">
                                    <Link to={main.link} className="link-btn wh">EĞİTİMİ İNCELE</Link>
                                </div>
                            </div>
                    }


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roleType:state.sessionReducer.role_id
});

export default connect(mapStateToProps)(MainBannerComponent);