import React, { Component } from 'react';

class AreYouSureModalComponent extends Component {
    contentBlock = React.createRef();
    render() {
        let { toggleCloseDelete } = this.props;
        return (
            <div className="lb-root" onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleCloseDelete(false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block">
                        <div className="content-item">
                            <i className="icon-close" onClick={() => {
                                toggleCloseDelete(false)
                            }}></i>
                            <div className="text-item">
                                <p className="head-text">Katılımcı Silinecek. Emin misiniz?</p>
                            </div>
                            <div className="btn-block">
                                <span className="btn-red" onClick={() => {
                                    toggleCloseDelete(true);
                                }}>KATILIMCIYI SİL</span>
                                <span className="btn-2" onClick={() => {
                                    toggleCloseDelete(false)
                                }}>GERİ DÖN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default AreYouSureModalComponent;