import React, {Component,Fragment} from 'react'
import ModalEducationInfoComponent from '../../components/modals/modalEducationInfoComponent';
import {connect} from "react-redux";
import {
    educationAddParticipantAction, getDepartmentParticipantsAction, participantAutoCompleteAction,
    startEducationSessionAction
} from "../../actions/educationDetailAction";
import {departmentAutoCompleteAction} from "../../actions/autoCompleteAction";
import LoaderModalComponent from "../common/loaderModalComponent";

class AddParticipantsModalComponent extends Component {
    contentBlock = React.createRef();
    autoCompleteInput = React.createRef();
    participantAutoCompleteInput = React.createRef();
    errorMessage;
    isStarting = false;
    formValues = {
        selectedLocation: "",
        addedParticipants: []
    };
    //TODO:ONLINE EDUCATION EDITED
    activeTab="lokasyon"
    constructor(props) {
        super(props);
        this.formValues.addedParticipants = props.participants;
    }

    addParticipants = () => {
        if (this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length === 0 || item.tc_no.trim().length !== 11).length === 0) {
            this.formValues.addedParticipants.push({participant_name_surname: "", tc_no: ""});
            this.forceUpdate();
        }
    };
    checkFormValues = () => {
        this.errorMessage = null;
        if ((!this.formValues.selectedLocation || this.formValues.selectedLocation.trim() === "") && this.props.isStarted === false && this.activeTab==="lokasyon") {
            this.errorMessage = "Lütfen lokasyon bilgisi girin.";
            this.forceUpdate();
            return false;
        }
        if (this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length > 0 && item.tc_no.trim().length === 11).length === 0) {
            this.errorMessage = "Lütfen en az 1 katılımcı girin.";
            this.forceUpdate();
            return false;
        }
        ;
        if (this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length === 0 || item.tc_no.trim().length !== 11).length > 0) {
            this.errorMessage = "Lütfen bütün alanları doldurunuz.";
            this.forceUpdate();
            return false;
        }
        return true;
    };
    startEducation = () => {
        if (this.checkFormValues() === true) {
            let urlArr = this.props.urlLink.split("-");
            if (this.props.isStarted !== true) {
                if(this.isStarting === false){
                    this.isStarting = true;
                    this.forceUpdate();
                    //TODO:ONLINE EDUCATION EDITED
                    this.props.dispatch(startEducationSessionAction({
                        education_id: urlArr[urlArr.length - 1],
                        location_id: this.formValues.selectedLocation,
                        sessionType: this.activeTab
                    }, (res, sessionId) => {
                        if (res === true) {
                            this.props.dispatch(educationAddParticipantAction({
                                participants: this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length > 0 && item.tc_no.trim().length === 11),
                                education_id: urlArr[urlArr.length - 1],
                                education_session_id: sessionId,
                                sessionType: this.activeTab
                            }, (result) => {
                                if (result) {
                                    if(this.activeTab==="online"){
                                        this.props.history.push("/quiz-sonuc/"+sessionId)
                                    }else{
                                        this.props.toggleModal(false);
                                        this.props.startEducation(result, sessionId);
                                        this.isStarting = false;
                                        this.forceUpdate();
                                    }

                                }
                            }))
                        }

                    }));
                }

            } else {
                this.props.dispatch(educationAddParticipantAction({
                    participants: this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length > 0 && item.tc_no.trim().length === 11),
                    education_id: urlArr[urlArr.length - 1],
                    education_session_id: this.props.sessionId
                }, (result) => {
                    if (result) {
                        this.props.toggleModal(false);
                        this.props.startEducation(result, null);
                    }
                }))
            }
            /*this.props.dispatch(educationAddParticipantAction({
             participants: this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length > 0 && item.tc_no.trim().length === 11),
             education_id: urlArr[urlArr.length - 1]
             }, (result) => {
             if (result) {
             if (this.props.isStarted === false) {
             this.props.dispatch(startEducationSessionAction({
             education_id: urlArr[urlArr.length - 1],
             location_id: this.formValues.selectedLocation
             }, (res,sessionId) => {
             if (res === true) {
             this.props.toggleModal(false);
             this.props.startEducation(result,sessionId);
             }
             }));
             }else{
             this.props.toggleModal(false);
             this.props.startEducation(result);
             }
             }
             }));*/
        }
    };
    locationOnChange = (e) => {
        this.formValues.selectedLocation = e.target.value;
        this.checkFormValues();
        this.forceUpdate();
    };
    textInputValueChange = (item, e, key) => {
        item[key] = key === "tc_no" ? e.target.value.replace(/[^\d]/g, '').substring(0, 11) : e.target.value;
        this.checkFormValues();
        this.forceUpdate()
    };
    deleteFromArray = (index) => {
        this.formValues.addedParticipants.splice(index, 1);
        this.forceUpdate();
    };
    onChangeFunc = (value) => {
        this.props.dispatch(departmentAutoCompleteAction(value, (res) => {
            this.autoIsShowing = true;
            this.autoCompleteArr = res;
            this.forceUpdate();
        }))
    };
    selectedFunc = (item) => {
        this.props.dispatch(getDepartmentParticipantsAction(item, (res) => {
            this.autoCompleteInput.current.value = "";
            this.autoIsShowing = false;
            this.autoCompleteArr = [];
            this.formValues.addedParticipants = [...this.formValues.addedParticipants, ...res];
            this.forceUpdate();
        }));
    };
    participantAutoComplete = (value) => {
        this.props.dispatch(participantAutoCompleteAction({name: value}, (res) => {
            this.participantAutoIsShowing = true;
            this.participantAutoCompleteArr = res;
            this.forceUpdate();
        }))
    };
    selectedParticipantFunc = (item) => {
        this.participantAutoCompleteInput.current.value = "";
        this.participantAutoIsShowing = false;
        this.participantAutoCompleteArr = [];
        this.formValues.addedParticipants.push(item);
        this.forceUpdate();
    };
    departmentOnBlurFunc = (e) => {
        setTimeout(() => {
            if(this.autoCompleteInput.current){
                this.autoCompleteInput.current.value = "";
            }
            this.autoIsShowing = false;
            this.autoCompleteArr = [];
            this.forceUpdate();
        }, 250)
    };
    participantOnBlurFunc = (e) => {
        setTimeout(() => {
            if(this.participantAutoCompleteInput.current){
                this.participantAutoCompleteInput.current.value = "";
            }
            this.participantAutoIsShowing = false;
            this.participantAutoCompleteArr = [];
            this.forceUpdate();
        }, 250)
    };

    setActiveTab = (activeTab) => {
        this.activeTab = activeTab;
        this.forceUpdate();
    }

    render() {
        let {toggleModal, urlLink, locations, isStarted} = this.props;
        return (
            <Fragment>
                <div className="lb-root" onClick={(e) => {
                    if (this.contentBlock.current.contains(e.target) === false) {
                        toggleModal(false)
                    }
                }}>
                    <div className="lb-block">
                        <div ref={this.contentBlock} className="content-block add-part-block">

                            <div className="content-item">
                                <ModalEducationInfoComponent isParticipantModal={true} activeTab={this.activeTab} setActiveTab={this.setActiveTab} urlLink={urlLink}/>

                                <i className="icon-close" onClick={() => {
                                    toggleModal(false)
                                }}></i>


                                <div className="form-block">
                                    {
                                        (isStarted !== true && this.activeTab !=="online") &&
                                        <div className="select-container">
                                            <div className="select-block">
                                                <label>Lokasyon <span>(Eğitim verdiğiniz bölgeyi seçiniz.)</span></label>
                                                <select value={this.formValues.selectedLocation}
                                                        onChange={this.locationOnChange}>
                                                    <option value={""}>Seçiniz</option>
                                                    {
                                                        locations && locations.map((locationItem, key) =>
                                                            <option key={key}
                                                                    value={locationItem.id}>{locationItem.name}</option>
                                                        )
                                                    }
                                                </select>
                                                <i className="icon-arr-bottom"></i>
                                            </div>
                                        </div>
                                    }
                                    <div className="input-container">
                                        <div className="input-block">
                                            <div className="input-container">
                                                <div className="input-block">
                                                    <div className="input-item">
                                                        <label>Departman Ekle<input onBlur={this.departmentOnBlurFunc} autoComplete="off"
                                                                                    ref={this.autoCompleteInput}
                                                                                    onChange={(e) => {
                                                                                        this.onChangeFunc(e.target.value)
                                                                                    }} type="text"
                                                                                    placeholder="Departman Adı Giriniz"/></label>
                                                        {
                                                            (this.autoIsShowing === true && this.autoCompleteArr && this.autoCompleteArr.length > 0) &&
                                                            <div className="autocomplete-root">
                                                                <ul>
                                                                    {
                                                                        this.autoCompleteArr.map((item, key) =>
                                                                            <li onClick={() => {
                                                                                this.selectedFunc(item)
                                                                            }} key={key}>{item.name?item.name:item.mission_name}</li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <div className="input-block">
                                            <div className="input-container">
                                                <div className="input-block">
                                                    <div className="input-item">
                                                        <label>Katılımcı Ekle
                                                            <input autoComplete="off" onBlur={this.participantOnBlurFunc}
                                                                   ref={this.participantAutoCompleteInput}
                                                                   onChange={(e) => {
                                                                       this.participantAutoComplete(e.target.value)
                                                                   }} type="text"
                                                                   placeholder="Katılımcı Adı Giriniz"/></label>
                                                        {
                                                            (this.participantAutoIsShowing === true && this.participantAutoCompleteArr && this.participantAutoCompleteArr.length > 0) &&
                                                            <div className="autocomplete-root">
                                                                <ul>
                                                                    {
                                                                        this.participantAutoCompleteArr.map((item, key) =>
                                                                            <li onClick={() => {
                                                                                this.selectedParticipantFunc(item)
                                                                            }} key={key}>{item.participant_name_surname} - {item.tc_no}{item.mission_name?", "+item.mission_name:""}{item.department_name?","+ item.department_name:""}</li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-container">
                                        <label>KATILIMCILAR <span>(Katılımcıları ve T.C Kimlik Numaralarını giriniz.)</span></label>
                                        <div className="input-blocks">
                                            {
                                                this.formValues.addedParticipants.map((item, key) =>
                                                    <div className={"input-block "} key={key}>
                                                        <div
                                                            className={"input-item " + (item.participant_name_surname.length > 0 ? "" : "error")}>
                                                            <input onChange={(e) => {
                                                                this.textInputValueChange(item, e, "participant_name_surname")
                                                            }} type="text" value={item.participant_name_surname}
                                                                   placeholder="Adı Soyadı" disabled={true}/>
                                                        </div>
                                                        <div
                                                            className={"input-item " + (item.tc_no.length === 11 ? "" : "error")}>
                                                            <input onChange={(e) => {
                                                                this.textInputValueChange(item, e, "tc_no")
                                                            }} type="text" placeholder="T.C. Kimlik No"
                                                                   value={item.tc_no} disabled={true}/>
                                                            <i className="icon-close" onClick={() => {
                                                                this.deleteFromArray(key)
                                                            }}></i>

                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        {/*<div className="add-btn-item">
                                         <span className={"add-btn "+(this.formValues.addedParticipants.filter(item => item.participant_name_surname.trim().length === 0 || item.tc_no.trim().length !== 11).length===0 ? "":" disabled")} onClick={this.addParticipants}>
                                         <i className="icon-plus-circle"></i>KATILIMCI EKLE</span>
                                         </div>*/}
                                    </div>
                                </div>
                                <div className={"bottom-btn-item "}>
                                    <p>{this.errorMessage ? this.errorMessage : ""}</p>
                                    <span className="btn-green"
                                          onClick={this.startEducation}>{isStarted === true ? "EĞİTİME DEVAM ET" : "EĞİTİME BAŞLA"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.isStarting === true && <LoaderModalComponent/>
                }
            </Fragment>

        )
    }
}
;

const mapStateToProps = (state) => ({
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(AddParticipantsModalComponent);