import React, {Component} from 'react';
import moment from "moment";

class QuizParticipantsInfoComponent extends Component {
    sortKey = "name_surname";
    sortType = "asc";
    calculateDateDiff = (firstDate,secondDate) => {
        let firstDateMoment = moment(firstDate);
        let secondDateMoment = moment(secondDate);
        let hours = firstDateMoment.diff(secondDateMoment,'hours')
        let minutes = firstDateMoment.diff(secondDateMoment,'minutes')%60
        let seconds = firstDateMoment.diff(secondDateMoment,'seconds')%60
        return `${hours && hours>0?(hours<10?"0":"")+hours:"00"}:${minutes && minutes>0?(minutes<10?"0":"")+minutes:"00"}:${seconds && seconds>0?(seconds<10?"0":"")+seconds:""}`
    }
    changeSortType = (sortKey) => {
        this.sortKey = sortKey;
        this.sortType = this.sortType==="asc"?"desc":"asc";
        this.forceUpdate();
    }
    sortFunc = (firstItem,secondItem) => {
        if(this.sortKey === "education_status"){
            if(firstItem.info.end_date){
                return this.sortType === "asc" ? 1:-1;
            }
            if(secondItem.info.end_date){
                return this.sortType === "asc" ? -1:1;
            }
            if(firstItem.info.started_date){
                return this.sortType === "asc" ? 1:-1;
            }
            if(secondItem.info.started_date){
                return this.sortType === "asc" ? -1:1;
            }
            if(firstItem.info.education_start_date){
                return this.sortType === "asc" ? 1:-1;
            }
            if(secondItem.info.education_start_date){
                return this.sortType === "asc" ? -1:1;
            }
            return 0;
        }else{
            let firstItemValue = firstItem.info.participant[this.sortKey]?firstItem.info.participant[this.sortKey]:(this.sortKey==="correct_count"?firstItem.correct_count:"")
            let secondItemValue = secondItem.info.participant[this.sortKey]?secondItem.info.participant[this.sortKey]:(this.sortKey==="correct_count"?secondItem.correct_count:"")
            if (firstItemValue < secondItemValue ) {
                return this.sortType === "asc" ? -1:1;
            }
            if (firstItemValue > secondItemValue) {
                return this.sortType === "asc" ? 1:-1;
            }
            return 0;
        }
    }
    render() {
        let {quizResults, participants} = this.props;
        return (
            <div className="participants-root">
                <div className="head-info-item">
                    <p className="head-text">Katılımcılar</p>
                    <span>Toplam Katılımıcı : <span>{quizResults ? quizResults.length:0}</span></span>
                </div>
                <div className="participants-head-block">
                    <div className="head-item" onClick={()=>{
                        this.changeSortType("name_surname")
                    }}>
                        <p>Kullanıcı Adı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="head-item" onClick={()=>{
                        this.changeSortType("education_status")
                    }}>
                        <p>Eğitim Durumu</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="head-item" onClick={()=>{
                        this.changeSortType("correct_count")
                    }}>
                        <p>Doğru Sayısı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="head-item" onClick={()=>{
                        this.changeSortType("tc_no")
                    }}>
                        <p>T.C. Kimlik No</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                </div>
                <div className="participants-container">
                    {
                        quizResults && quizResults.sort(this.sortFunc).map((resultItem,key)=>
                            <div className="participants-block" key={key}>
                                <div className="participants-tab-item">
                                    <p>{resultItem.info.participant.name_surname}</p>
                                    <p>{resultItem.info.participant.tc_no}</p>
                                </div>
                                <div className="participants-info-block">
                                    <div className="participants-item">
                                        <p>Eğitim Durumu</p>
                                        <span>{!resultItem.info.education_start_date?"Başlamadı":(resultItem.info.started_date?"Bitti":"Başladı")}</span>
                                    </div>
                                    <div className="participants-item">
                                        <p>Eğitim Süresi</p>
                                        <span>{resultItem.info.education_start_date && resultItem.info.started_date ?this.calculateDateDiff(resultItem.info.started_date,resultItem.info.education_start_date):"-"}</span>
                                    </div>
                                    <div className="participants-item">
                                        <p>Quiz Durumu</p>
                                        <span>{!resultItem.info.started_date?"Başlamadı":(resultItem.info.end_date?"Bitti":"Başladı")}</span>
                                    </div>
                                    <div className="participants-item">
                                        <p>Quiz Süresi</p>
                                        <span>{resultItem.info.end_date && resultItem.info.started_date ?this.calculateDateDiff(resultItem.info.end_date,resultItem.info.started_date):"-"}</span>
                                    </div>
                                    <div className="participants-item">
                                        <p>Doğru Sayısı</p>
                                        <span>{resultItem.info.end_date?resultItem.correct_count:"-"}</span>
                                    </div>

                                </div>

                            </div>
                        )
                    }


                </div>
            </div>

        );
    }
}

export default QuizParticipantsInfoComponent;