import React,{Component} from 'react';
import DatePicker, {setDefaultLocale} from "react-datepicker";
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";
import Helper from '../../helper';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/tr'
moment.locale('tr-TR');
setDefaultLocale('tr');

class DocumentPrintModalComponent extends Component{
    contentBlock = React.createRef();
    constructor(props){
        super();
        this.startDate = new Date();
    };

    handleChange = (date) => {
        this.startDate = date;
        this.forceUpdate();
    };
    printDocument = () => {
        let myWindow = window.open('', 'PRINT', 'height=400,width=600');
        let participants = [];
        this.props.participants.map((item) => {
            let participantItem = {
                name_surname: item.info ? (item.info.participant?item.info.participant.name_surname:item.info.name_surname) : item.participant.name_surname,
                score: item.info ? item.correct_count + "/" + item.total_count : "-",
                tc_no: item.info ? (item.info.participant?item.info.participant.tc_no:item.info.tc_no) : item.participant.tc_no
            };
            participants.push(participantItem);
        });
        myWindow.document.write(Helper.getOfficialReport(participants, this.props.sessionDetail.user_name_surname, "", this.props.educationDetail.name, moment(this.startDate).format('D MMMM')));
        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        myWindow.close();
        return true;
    };
    downloadDocument = () => {
        let participants = [];
        this.props.participants.map((item) => {
            let participantItem = {
                name_surname: item.info ? (item.info.participant?item.info.participant.name_surname:item.info.name_surname) : item.participant.name_surname,
                score: item.info ? item.correct_count + "/" + item.total_count : "-",
                tc_no: item.info ? (item.info.participant?item.info.participant.tc_no:item.info.tc_no) : item.participant.tc_no
            };
            participants.push(participantItem);
        });
        let pdf = new jsPDF('p', 'pt', 'letter');
        let source = Helper.getOfficialReport(participants, this.props.sessionDetail.user_name_surname, "", this.props.educationDetail.name, moment(this.startDate).format('D MMMM'));
        let specialElementHandlers = {
            '#bypassme': function (element, renderer) {
                return true
            }
        };
        let margins = {
            top: 80,
            bottom: 60,
            left: 40,
            width: 522
        };
        pdf.fromHTML(
            source,
            margins.left,
            margins.top, {
                'width': margins.width,
                'elementHandlers': specialElementHandlers
            },

            (dispose) => {
                pdf.save('egitim-dokumani.pdf');
            }, margins);
    };
    submit = () => {
      if(this.props.type === "print") {
          this.printDocument()
      } else{
          this.downloadDocument();
      }
    };
    render(){
        let {toggleModal,type} = this.props;
        return(
            <div className="lb-root" onClick={(e)=>{
                if(this.contentBlock.current.contains(e.target) === false){
                    toggleModal(false)
                }
            }}>
                <div className="lb-block">
                    <div ref={this.contentBlock} className="content-block add-part-block add-new-block">

                        <div className="content-item">

                            <div className="head-block">
                                <h2>Belge {type === "print" ? "Yazdır":"İndir"} </h2>
                                <p>Belge {type === "print" ? "yazdırmak":"indirmek"} için bir tarih seçiniz.</p>
                            </div>

                            <i className="icon-close" onClick={() => {
                                toggleModal(false)
                            }}></i>


                            <div className="form-block">
                                <div className="input-container">
                                    <div className="input-block">
                                        <div className="input-item">
                                            <label>
                                                Belge Tarihi
                                                <div className="date-input-block">
                                                    <DatePicker
                                                        dateFormat="dd/MM/YYYY"
                                                        selected={this.startDate}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-btn-item">
                                {/*<p>{submitFailed === true && errArr.length>0 ? formErrors[errArr[0]] : (this.errorMsg ? this.errorMsg :"") }</p>*/}
                                <button onClick={this.submit} className="btn-green">{type === "print" ? "YAZDIR":"İNDİR"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default DocumentPrintModalComponent;