import React, {Component} from 'react';
import {EDUCATIONDETAIL_WITHOUTPARAM, PANEL_DOCUMENTS_WITOUTPARAM} from '../../constants/routePaths';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
import {Link} from "react-router-dom";
import Helper from '../../helper';
import NewEducationModalComponent from "../modals/newEducationModalComponent";

moment.locale('tr', trLocale);

class PanelDocItemComponent extends Component {
    isClicked = false;
    toggleModal = (val) =>{
        this.isClicked = val;
        this.forceUpdate();
    };

    render() {
        let {name, education_name,created_date, is_active, id, last_updated_date, isReport,pathname,education_id,sessionCount,totalParticipantCount, percent,endDate,startDate} = this.props;
        return (
            name || education_name ?
                <div>
                    <Link
                        to={isReport?`${pathname}/${education_id}?endDate=${endDate}${startDate?"&startDate="+startDate:""}` : `${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(name || education_name).split(" ").join("-").split("?").join("").split("/").join("").split("?").join("")}-${id}`} className="table-item">
                        <div className="td-item t-1">
                            <i className="icon-doc"></i>
                            <p>{name || education_name}</p>
                        </div>
                        {
                            isReport === true &&
                            <div className="td-item t-1">
                                <p>{sessionCount}</p>
                            </div>
                        }
                        {
                            isReport === true &&
                            <div className="td-item t-1">
                                <p>{totalParticipantCount}</p>
                            </div>
                        }

                        {
                            isReport !==true &&
                            <div className="td-item t-2">
                                <p>{is_active === "1" ? "Yayında" : "Yayında Değil"}</p>
                            </div>
                        }
                        <div className="td-item t-3 resp-date">
                            <p>{isReport ? `%${Math.round(percent)}` : (last_updated_date ? moment(last_updated_date).format('LL') : moment(created_date).format('LL'))}</p>
                        </div>
                        {
                            isReport!==true &&
                            <div className="td-item t-4 resp-date">
                            <span onClick={(e) => {
                                // e.stopPropagation();
                                e.preventDefault();
                                this.props.history.push(`${this.props.match.params.subPage ? this.props.match.params.subPage : this.props.match.url}?education_id=${id}`)
                                this.toggleModal(true);
                            }}>Güncelle</span>
                            </div>
                        }

                    </Link>
                    {
                        this.isClicked &&
                        <NewEducationModalComponent fromUpdate={true} toggleModal={this.toggleModal} {...this.props}/>
                    }
                </div>
                :
                <div></div>
        )
    }
}

export default PanelDocItemComponent;