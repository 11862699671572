import React, {Component} from 'react';
import InfiniteScroll from "react-infinite-scroller";
import {connect} from "react-redux";
import {getEducationParticipantsAction, getEducationsByParticipantsAction} from "../../actions/reportAction";
import EducationParticipantItemComponent from "./educationParticipantItemComponent";
import {Link} from "react-router-dom";
import XLSX from 'xlsx';
import {QUIZRESULT_WITHOUT_PARAM} from "../../constants/routePaths";
import DatePickerInputComponent from "./datepickerComponent";
import moment from "moment";
import queryString from "query-string";
import {getPanelLocationAction} from "../../actions/panelAction";

class EducationByParticipantListComponent extends Component {
    activePage = 0;
    order = "name_surname";
    order_type = "asc";
    isMore = true;
    textInput = React.createRef();
    startDate;
    endDate = moment().format('YYYY-MM-DD');
    locationId;
    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search);
        if (values.startDate) {
            this.startDate = values.startDate;
        }
        if (values.endDate) {
            this.endDate = values.endDate;
        }
        this.loadFunc();
        this.getLocations()
    }

    downloadResultAsExcel = () => {
        let {items} = this.props;
        if (items && items[0]) {
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: items[0].participant_name_surname,
            };
            wb.SheetNames.push(items[0].participant_name_surname);
            let sheetItems = [];
            items.map(item => {
                sheetItems.push([item.education_name, item.location_name ? item.location_name : "Online", item.session_start_date, item.user_name_surname, item.total_count > 0 ? `${item.correct_count}/${item.total_count}` : "-"])
            })
            var ws_data = [["Katılımcı Adı", this.props.items[0].participant_name_surname], ['Eğitim Adı', 'Lokasyon', "Eğitim Tarihi", 'Eğitmen Adı'], ...sheetItems];
            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.Sheets[items[0].participant_name_surname] = ws;
            var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
            var buf = new ArrayBuffer(wbout.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
            this.saveFile(new Blob([buf], {type: "application/octet-stream"}), "_Sorular");
        }

    };

    saveFile = (blob, filename) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${this.props.items[0].participant_name_surname.split(" ").join("-")}-aldigi-egitimler.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            searchTerm: this.textInput.current ? this.textInput.current.value : '',
            participant_id: this.props.match.params.subPageId,
            startDate: this.startDate,
            endDate: this.endDate,
            locationId: this.locationId
        };
        this.isMore = false;
        this.forceUpdate();
        this.props.dispatch(getEducationsByParticipantsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    inputKeyDown = (e) => {
        this.activePage = 0;
        this.loadFunc();
    }
    changeEndDate = (endDate) => {
        this.endDate = endDate;
        this.activePage = 0;
        this.loadFunc();
    }
    changeStartDate = (startDate) => {
        this.startDate = startDate;
        this.activePage = 0;
        this.loadFunc();
    }
    locationOnChange = (e) => {
        this.locationId = e.target.value;
        this.activePage = 0;
        this.loadFunc();
    }
    getLocations = () => {
        this.props.dispatch(getPanelLocationAction({
            activePage: 0,
            order: "name",
            order_type: "asc"
        }, (res, isMore) => {
        }));
    }

    render() {
        let {items, locations} = this.props;
        return (
            <div className="panel-content-block">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head table-filter">
                            {(items && items[0]) && <p>{items[0].participant_name_surname}</p>}
                            {(items && items[0]) &&
                            <button className="btn-2" onClick={this.downloadResultAsExcel}>Excel İndir</button>}
                        </div>
                        <div className="table-head table-filter">
                            <div className="select-container report">
                                <div className="select-block">
                                    {
                                        locations &&
                                        <select onChange={this.locationOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                locations.map((locationItem, key) =>
                                                    <option key={key}
                                                            value={locationItem.id}>{locationItem.name}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            <DatePickerInputComponent changeStartDate={this.changeStartDate} startDate={this.startDate}
                                                      endDate={this.endDate}
                                                      changeEndDate={this.changeEndDate}/>
                        </div>
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={() => {
                                //this.sortFunc("education.name")
                            }}>
                                <p>Eğitim Adı</p>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                //this.sortFunc("education.name")
                            }}>
                                <p>Lokasyon</p>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                //this.sortFunc("education.name")
                            }}>
                                <p>Eğitmen Adı</p>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                //this.sortFunc("education.name")
                            }}>
                                <p>Eğitim Tarihi</p>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                //this.sortFunc("education.name")
                            }}>
                                <p>Quiz Sonucu</p>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items && items.map((item, key) =>
                                    <Link to={`${QUIZRESULT_WITHOUT_PARAM}${item.education_session_id}`}
                                          className="table-item" key={key}>
                                        <div className="td-item t-1">
                                            <i className="icon-doc"></i>
                                            <p>{item.education_name}</p>
                                        </div>
                                        <div className="td-item t-1">
                                            <p>{item.location_name ? item.location_name : "Online"}</p>
                                        </div>
                                        <div className="td-item t-1">
                                            <p>{item.user_name_surname}</p>
                                        </div>
                                        <div className="td-item t-1">
                                            <p>{item.session_start_date}</p>
                                        </div>
                                        <div className="td-item t-1">
                                            <p>{item.total_count > 0 ? `${item.correct_count}/${item.total_count}` : "-"}</p>
                                        </div>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.education_by_participant ? state.PanelReducer.education_by_participant[ownProps.match.params.subPageId] : null,
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(EducationByParticipantListComponent);
