import React from 'react';
import Helper from '../../helper'

const PanelUserItemComponent = ({id,name_surname,role,is_active,last_edited_date,toggleNewUserModal,isReport,goToDetail,totalSessionCount,totalParticipantCount,correct_percent}) => (
    <div className="table-item" onClick={()=>{
        if(isReport){
            goToDetail(id)
        }else{
            toggleNewUserModal(true,id)
        }
    }}>
        <div className="td-item t-1">
            <i className={"icon-user2"}></i>
            <p>{Helper.titleCase(name_surname)}</p>
        </div>
        <div className="td-item t-2">
            <p>{isReport?totalSessionCount:role}</p>
        </div>
        <div className={isReport ? "td-item t-1":"td-item t-2"}>
            <p>{isReport?totalParticipantCount: (is_active === "1" ? "Aktif" : "Pasif")}</p>
        </div>
        {
            isReport!==true &&
            <div className="td-item t-3 resp-date">
                <p>{last_edited_date}</p>
            </div>
        }
        {
            isReport &&
            <div className="td-item t-2">
                <p>%{Math.round(correct_percent)}</p>
            </div>
        }
    </div>
);

export default PanelUserItemComponent;