import React from 'react';
import {Link} from "react-router-dom";
import {QUIZRESULT_WITHOUT_PARAM} from '../../constants/routePaths';
import Helper from '../../helper';

const PanelEducationItemComponent = ({id,education_name,teacher,location,education_start_date,toggleDetail,subPage,history,session_type}) => (
    <div className="table-item" onClick={()=>{if(subPage==="tamamlanmis" || subPage==="devam-eden"){
        history.push(QUIZRESULT_WITHOUT_PARAM+id);
    }}}>
        {/*<Link className="table-item" to={QUIZRESULT_WITHOUT_PARAM+id}>*/}
        <div className="td-item t-1">
            <i className={"icon-calendar"}></i>
            <p>{Helper.titleCase(education_name)}</p>
        </div>
        <div className="td-item t-2">
            <p>{teacher}</p>
        </div>
        <div className="td-item t-3 resp-local">
            <p>{location?location:session_type}</p>
        </div>
        <div className="td-item t-3 resp-date">
            <p>{education_start_date}</p>
        </div>
    </div>
);

export default PanelEducationItemComponent;