export const HOME = "/";
export const LOGIN = "/giris";
export const SIGNUP = "/uye-ol";
export const FORGOTPASS = "/sifremi-unuttum";
export const TOKEN_PASSWORD = "/parola-olustur/:token/:user_id";
export const EDUCATIONDETAIL = "/egitim/:educationLink";
//TODO:ONLINE EDUCATION EDITED
export const ONLINE_EDUCATIONDETAIL = "/online-egitim/:educationLink/:session_id";
export const ONLINE_EDUCATIONDETAIL_WITHOUT_PARAM = "/online-egitim/";
export const EDITEDUCATIONDETAIL = "/egitim-duzenle/:educationLink";
export const EDITEDUCATIONVERSIONDETAIL = "/egitim-onayla/:educationLink";
export const EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM = "/egitim-onayla/";
export const EDITSMARTEDUCATIONDETAIL = "/akilli-dokuman-duzenle/:educationLink";
export const EDITSMARTEDUCATIONDETAIL_WITHOUTPARAM = "/akilli-dokuman-duzenle/";
export const EDITEDUCATIONDETAIL_WITHOUTPARAM = "/egitim-duzenle/";
export const EDUCATIONDETAIL_WITHOUTPARAM = "/egitim/";
export const ALLEDUCATIONS = "/tum-egitimler";
export const QUIZPROCESS = "/quiz/:education_session_id/:quiz_id";
export const QUIZPROCESS_WITHOUT_PARAMS = "/quiz/";
export const QUIZWEB = "/quiz-coz/:token/:educationSessionId/:quizId/:questionId";
export const QUIZWEB_WITHOUTPARAM = "/quiz-coz/";
export const QUIZWEBTEACHER = "/egitmen-quiz-coz/:educationSessionId/:quizId/:questionId";
export const QUIZWEBTEACHER_WITHOUT_PARAMS = "/egitmen-quiz-coz/";
// export const QUIZRESULT = "/quiz-sonuc/:sessionId/:quizSessionId?";
export const QUIZRESULT = "/quiz-sonuc/:sessionId";
export const QUIZRESULT_WITHOUT_PARAM = "/quiz-sonuc/";
export const PANELHOME = "/panel/";
export const PANELDASHBOARD = "/panel/dashboard/";
export const PANEL = "/panel/:page/:subPage?/:educationId?";
export const PANEL_DOCUMENTS = "/panel/dokumanlar/:subPage?";
export const PANEL_REPORTS = "/panel/raporlar/:subPage?/:subPageId?";
export const PANEL_REPORTS_EDUCATION_RANKING_WITHOUT_PARAM = "/panel/raporlar/eğitim-basari-siralamasi/";
export const PANEL_REPORTS_EDUCATION_RANKING = "/panel/raporlar/eğitim-basari-siralamasi/:educationId?";
export const PANEL_DOCUMENTS_WITOUTPARAM = "/panel/dokumanlar";
export const PANEL_SMARTDOCUMENTS = "/panel/akilli-dokumanlar/:subPage?";
export const PANEL_SMARTDOCUMENTS_WITOUTPARAM = "/panel/akilli-dokumanlar";
export const PANEL_SURVEY = "/panel/anketler";
export const PANEL_PARTICIANTS = "/panel/katilimcilar";
export const PANEL_USERS = "/panel/kullanicilar/:subPage?";
export const PANEL_USERS_WITOUTPARAM = "/panel/kullanicilar";
export const PANEL_EDUCATIONS = "/panel/egitimler/:subPage?";
export const PANEL_EDUCATIONS_WITOUTPARAM = "/panel/egitimler";
export const PANEL_FEEDBACK = "/panel/geri-bildirimler/:subPage?";
export const PANEL_FEEDBACK_WITOUTPARAM = "/panel/geri-bildirimler";
export const PANEL_QUIZ = "/panel/quizler/:subPage?";
export const PANEL_QUIZ_WITOUTPARAM = "/panel/quizler";
export const PANEL_TAG_WITHOUTPARAM = "/panel/etiketler";
export const PANEL_TAG = "/panel/etiketler/:subPage?";

export const PANEL_DRAFTS = "/panel/taslaklar/:subPage?";
export const PANEL_DRAFTS_WITOUTPARAM = "/panel/taslaklar";
export const PANEL_LOCATIONS = "/panel/lokasyonlar/:subPage?";
export const PANEL_LOCATIONS_WITOUTPARAM = "/panel/lokasyonlar";
export const PANEL_SURVEYS = "/panel/anketler/:subPage?";
export const PANEL_SURVEYS_WITOUTPARAM = "/panel/anketler";
export const PANEL_REPORTS_WITOUTPARAM = "/panel/raporlar";
export const PANEL_WITHOUTPARAM = "/panel/";
export const DRAFT_DETAIL = "/panel/draft-detail/:educationLink";
export const DRAFT_DETAIL_WITHOUT_PARAM = "/panel/draft-detail/";
export const ADD_DOCUMENT_SESSION = "/import-document/:sessionId/:token?";
export const EDUCATION_TOKEN = "/egitim-token/:educationLink/:token";
export const EDUCATION_TOKEN_WITHOUT_PARAM = "/egitim-token/";