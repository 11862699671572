import React, {Component, Fragment} from 'react';
import {
    Redirect,
    Route,
    Switch, withRouter
} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";
import HomePage from '../pages/homePage';
import EducationDetailPage from '../pages/educationDetailPage';
import EditEducationsDetailPage from '../pages/editEducationsDetailPage';
import AllEducationsPage from '../pages/allEducationsPage';
import QuizProcessPage from '../pages/quizProcessPage';
import QuizResultPage from '../pages/quizResultPage';
import QuizWebPage from '../pages/quizWebPage';
import QuizWebTeacherPage from '../pages/quizWebTeacherPage';
import PanelPage from '../pages/panelPage';
import DraftDetailPage from '../pages/draftDetailPage';
import AddDocumentToSessionComponent from '../pages/AddDocumentToSessionPage';
import LoginPage from "../pages/loginPage";
import SignupPage from "../pages/signupPage";
import ForgotPassPage from "../pages/forgotPassPage";
import CreatePassPage from "../pages/cratePassPage";
import HeaderComponent from "./common/headerComponent";
import {connect} from "react-redux";
import {getActiveUserAction} from "../actions/userAction";
import EditSmartDocumentPage from '../pages/editSmartDocumentPage'
import EditChangedEducationsDetailPage from '../pages/editChangedEducationsDetailPage'
import EducationTokenPage from '../pages/educationTokenPage';
import QuizResultByEducationIdComponent from "./report/QuizResultByEducationIdComponent";
import newEducationModalComponent from "./modals/newEducationModalComponent";
import OnlineEducationDetailPage from "../pages/onlineEducationDetailPage";

class RoutesComponent extends Component {
    isLogin=null;
    constructor(props) {
        super(props);
        this.props.dispatch(getActiveUserAction((res) => {
            this.isLogin = res;
            this.forceUpdate();
        }));
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location.pathname!==this.props.location.pathname){
            this.props.dispatch(getActiveUserAction((res) => {
                this.isLogin = res;
                if(this.isLogin === false){

                }
            }));
        }else{
            this.isLogin = nextProps.isLogin;
        }
        return true;
    }

    render() {
        return (
            this.isLogin !== null ?
                <Fragment>
                    {
                        this.isLogin === false ?
                            <Switch>
                                <Route exact path={ROUTES.LOGIN} component={LoginPage}/>
                                <Route exact path={ROUTES.SIGNUP} component={SignupPage}/>
                                <Route exact path={ROUTES.FORGOTPASS} component={ForgotPassPage}/>
                                <Route exact path={ROUTES.TOKEN_PASSWORD} component={CreatePassPage}/>
                                <Route exact path={ROUTES.QUIZWEB} component={QuizWebPage}/>
                                <Route exact path={ROUTES.ADD_DOCUMENT_SESSION} component={AddDocumentToSessionComponent}/>
                                <Route exact path={ROUTES.EDUCATION_TOKEN} component={EducationTokenPage}/>
                                <Route exact path={ROUTES.ONLINE_EDUCATIONDETAIL} component={OnlineEducationDetailPage}/>
                                <Route component={() => <Redirect to={`${ROUTES.LOGIN}?redirect=${this.props.location.pathname}`}/>}/>
                            </Switch>
                            :
                            <Fragment>
                                <Switch>
                                    <Route exact path={ROUTES.QUIZWEB} component={null}/>
                                    <Route component={HeaderComponent}/>
                                </Switch>
                                <Switch>
                                    <Route exact path={ROUTES.HOME} component={HomePage}/>
                                    <Route exact path={ROUTES.EDUCATIONDETAIL} component={EducationDetailPage}/>
                                    <Route exact path={ROUTES.ONLINE_EDUCATIONDETAIL} component={OnlineEducationDetailPage}/>
                                    {
                                        (this.props.roleId == "1" || this.props.roleId == "3") && <Route exact path={ROUTES.EDITEDUCATIONDETAIL} component={EditEducationsDetailPage}/>
                                    }
                                    {
                                        (this.props.roleId == "1" || this.props.roleId == "3") && <Route exact path={ROUTES.EDITSMARTEDUCATIONDETAIL} component={EditSmartDocumentPage}/>
                                    }
                                    <Route exact path={ROUTES.ALLEDUCATIONS} component={AllEducationsPage}/>
                                    <Route exact path={ROUTES.DRAFT_DETAIL} component={DraftDetailPage}/>
                                    <Route exact path={ROUTES.PANELHOME} component={() => <Redirect to={ROUTES.PANELDASHBOARD}/>}/>
                                    <Route exact path={ROUTES.PANEL} component={PanelPage}/>
                                    <Route exact path={ROUTES.QUIZPROCESS} component={QuizProcessPage}/>
                                    <Route exact path={ROUTES.QUIZWEB} component={QuizWebPage}/>
                                    <Route exact path={ROUTES.QUIZWEBTEACHER} component={QuizWebTeacherPage}/>
                                    <Route exact path={ROUTES.QUIZRESULT} component={QuizResultPage}/>
                                    <Route exact path={ROUTES.ADD_DOCUMENT_SESSION} component={AddDocumentToSessionComponent}/>
                                    {
                                        this.props.roleId == "1" && <Route exact path={ROUTES.EDITEDUCATIONVERSIONDETAIL} component={EditChangedEducationsDetailPage}/>
                                    }
                                    <Route exact path={ROUTES.EDUCATION_TOKEN} component={EducationTokenPage}/>
                                </Switch>
                            </Fragment>
                    }
                </Fragment>
                :
                <div></div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLogin: state.sessionReducer.isLogin,
    roleId: state.sessionReducer.role_id
});

export default withRouter(connect(mapStateToProps)(RoutesComponent));
