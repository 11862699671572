import React, {Component, Fragment} from 'react';
import DatePicker, {setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/tr'
import {connect} from "react-redux";

moment.locale('tr-TR');
setDefaultLocale('tr');

class DatePickerInputComponent extends Component {
    handleStartDateChange = (date) => {
        this.startDate = date;
        this.props.changeStartDate(this.startDate ? moment(this.startDate).format('YYYY-MM-DD'):null)
        this.forceUpdate();
    };
    handleEndDateChange = (date) => {
        this.endDate = date;
        this.props.changeEndDate(this.endDate?moment(this.endDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'))
        this.forceUpdate();
    };
    render() {
        let {startDate,endDate} = this.props;
        return (
            <div className="date-picker-block">
                <div className="input-item">
                    <label>
                        <div className="date-input-block">
                            <DatePicker
                                selected={startDate ? new Date(startDate):this.startDate}
                                onChange={this.handleStartDateChange}
                                isClearable
                                placeholderText={"Başlangıç Tarihi"}
                            />
                        </div>
                    </label>
                </div>
                <div className="input-item">
                    <label>
                        <div className="date-input-block">
                            <DatePicker
                                selected={endDate?new Date(endDate):this.endDate}
                                onChange={this.handleEndDateChange}
                                minDate={this.startDate}
                                isClearable
                                placeholderText={"Bitiş Tarihi"}
                            />
                        </div>
                    </label>
                </div>
            </div>
        );
    }
};

export default connect()(DatePickerInputComponent);