import React,{Component} from 'react';
import QuizDurationComponent from "../../components/quiz/quizDurationComponent";
import Helper from '../../helper';

class QuizQuestionStatusContainer extends Component{
    render(){
        let {educationInfo,generalQuiz,quizInfo,quizTotalQuestionCount,quizQuestionCount,resultStatistic,sessionQuiz,participant,participantSession,educationSession} = this.props;
        let rate = !resultStatistic ? 0 : (resultStatistic.correctCount === 0 ? 0 : (resultStatistic.correctCount *100) / (resultStatistic.correctCount+resultStatistic.wrongCount) );
        return(
            <div className="status-root">
                <div className="text-block">
                    <div className="left-text-item">
                        <h2>{Helper.titleCase(educationInfo.name)}<br/>{participant ? Helper.titleCase(participant.name_surname):""}</h2>
                        <div className="tag-block">
                            <span className="label-tag grey">#MESLEKİ EĞİTİM</span>
                            <span className="label-tag grey">#QUIZ</span>
                        </div>
                    </div>
                    <div className="icon-item">
                        <i className="icon-book"></i>
                    </div>
                </div>
                <div className="status-block">
                    {
                        sessionQuiz && <QuizDurationComponent educationSession={educationSession} participantSession={participantSession} sessionQuiz={sessionQuiz} quizTotalQuestionCount={quizTotalQuestionCount} quizQuestionCount={quizQuestionCount} quizInfo={quizInfo}/>
                    }
                    <div className="status-item green-progress">
                        <div className="status-text-item">
                            <p>Doğru Cevaplar</p>
                            <p className="green">%{rate.toFixed(2)} Başarı</p>
                            <p>Yanlış Cevaplar</p>
                        </div>
                        <div className="status-bar">
                            <div className="progress-item" style={{width:rate+"%"}}>

                            </div>
                        </div>
                    </div>
                    {/*<div className="status-item green-progress">
                        <div className="status-text-item">
                            <p>Tamamlanan</p>
                            <p className="green">%{generalQuiz.toFixed(2)} Tamamlandı</p>
                            <p>Beklenen</p>
                        </div>
                        <div className="status-bar">
                            <div className="progress-item" style={{width:generalQuiz+"%"}}>

                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        )
    }
}

export default QuizQuestionStatusContainer;