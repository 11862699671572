import {
    getDepartmentQuizResult,
    getEducationQuizResult,
    getEducationSessionByYear,
    getEducationSessionDepartmentByYear,
    getEducationSessionUsersByYear,
    getQuizResultByEducation,
    getQuizResultByEducationId,
    getWeeklyEducationReport,
    getParticipantsByEducationId,
    getEducationsByUser, getEducationsByParticipants, getEducationParticipants
} from "../services/webService";
import * as actionTypes from "../constants/actionTypes"
export const getEducationSessionByYearAction = (params, cb) => dispatch => {
    getEducationSessionByYear(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        }
    })
};

export const getEducationSessionUsersByYearAction = (params,cb) => dispatch => {
    getEducationSessionUsersByYear(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getEducationSessionDepartmentByYearAction = (params,cb) => dispatch => {
    getEducationSessionDepartmentByYear(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getDepartmentQuizResultAction = (params,cb) => dispatch =>{
    getDepartmentQuizResult(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getEducationQuizResultAction = (params,cb) => dispatch => {
    getEducationQuizResult(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getParticipantsByEducationIdAction = (params,cb) => dispatch => {
    getParticipantsByEducationId(params).then((res)=>{
        if (res.data.success) {
            dispatch(reciveParticipantsByEducationId(res.data.success))
            cb(res.data.success);
        }
    });
};

export const getQuizResultByEducationAction = (params,cb) => dispatch => {
    getQuizResultByEducation(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        }
    });
};

export const getWeeklyEducationReportAction = (params,cb) => dispatch => {
    getWeeklyEducationReport(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
            dispatch(getWeeklyEducationResult(res.data.success,params.selectedWeek));
        }
    });
};

export const getWeeklyEducationResult = (data,week) => ({
    type: actionTypes.GET_EDUCATION_WEEKLY,
    payload: data,
    week:week
});


export const reciveParticipantsByEducationId = (payload) => ({
    type: actionTypes.RECEIVE_PANEL_PARTICIPANTS_EDUCATIONS,
    payload
});


export const receiveEducationParticipants = (payload, isFirst) => ({
    type: actionTypes.RECEIVE_EDUCATION_PARTICIPANT_REPORT,
    payload,
    isFirst
});

export const getEducationParticipantsAction = (params,cb) => dispatch =>{
    getEducationParticipants(params).then((res) => {
        if (res.data.success) {
            if(params.isFull){
                cb(res.data.success.participants,null,res.data.success.totalSessionTime);
            }else{
                dispatch(receiveEducationParticipants(res.data.success, params.activePage === 0));
                cb(true, res.data.state);
            }
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        console.log(err);
        cb(false, true);
    })
}


export const receiveEducationByParticipants = (payload, participant_id) => ({
    type: actionTypes.RECEIVE_EDUCATION_BY_PARTICIPANT_REPORT,
    payload,
    participant_id
});

export const getEducationsByParticipantsAction = (params,cb) => dispatch =>{
    getEducationsByParticipants(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveEducationByParticipants(res.data.success,params.participant_id));
        }
    }).catch((err) => {
        cb(false, true);
    })
}

export const receiveEducationsByUser = (payload, user_id) => ({
    type: actionTypes.RECEIVE_EDUCATION_BY_USER_REPORT,
    payload,
    user_id
});

export const getEducationsByUserAction = (params,cb) => dispatch =>{
    getEducationsByUser(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveEducationsByUser(res.data.success,params.user_id));
        }
    }).catch((err) => {
        cb(false, true);
    })
}


// export const getQuizResultByEducationIdAction = (params,cb) => dispatch => {
//     console.log("1111");
//     getQuizResultByEducationId(params).then((res)=>{
//         if (res.data.success) {
//             cb(res.data.success);
//             //dispatch(getEducationResult(res.data.success));
//         }
//     });
// };