import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import {matchPath, Prompt} from "react-router-dom";
import EducationDetailHeaderContainer from "../containers/educationDetail/educationDetailHeaderContainer";
import EducationDetailContainer from "../containers/educationDetail/educationDetailContainer";
import EducationWarningModalComponent from "../components/modals/educationWarningModalComponent";
import FeedBackModalComponent from "../components/modals/feedBackModalComponent";
import FeedBackResultModalComponent from "../components/modals/feedBackResultModalComponent";
import PhoneNumberModalContainer from "../containers/onlineEducation/phoneNumberModalContainer"
import Helper from "../helper";
import inputFieldComponent from "../components/common/formFields/inputFieldComponent";
import {getOnlineEducationParticipantAction} from "../actions/educationDetailAction";

class OnlineEducationDetailPage extends Component {
    phoneNumberInput = React.createRef();
    detailContent = React.createRef();
    detailContentComponent = React.createRef();
    webService;
    educationWarningIsShowing = false;
    shareModalIsShowing = false;
    educationWarningMessage;
    educationWarningDesc;
    quizIsShowing;
    feedBackModalIsShowing;
    feedBackResultModal = false;
    isQuizExist = false;
    docsType;
    sessionId;
    focusedId;
    headerComponent = React.createRef();
    participantDetail;
    state = {
        isBlocking: false,
        isEducationStarted:null
    }
    constructor(props) {
        super(props);
        //this.getPageData(props);
        this.props.dispatch(getOnlineEducationParticipantAction({session_id:this.props.match.params.session_id},(res,quizLink)=>{
            if(res){
                if(quizLink){
                    this.props.history.push(quizLink)
                }else{
                    this.participantDetail = res;
                    this.getPageData(props);
                }
            }else{
                this.setState({
                    isEducationStarted:false
                })
            }
        }))


    }

    getPageData = (props) => {
        this.webService = new PageService(props.location.pathname);
        let urlParams = matchPath(props.location.pathname, {
            path: ROUTES.ONLINE_EDUCATIONDETAIL,
            exact: false,
            strict: false
        });
        this.webService.getData(props.dispatch, urlParams);
        this.sessionId = props.match.params.session_id;
        this.forceUpdate();
        this.setState({
            isEducationStarted: true
        })
    };

    toggleModal = (value, message, desc, isQuiz, isQuizExist) => {
        this.educationWarningIsShowing = value;
        this.educationWarningMessage = message;
        this.educationWarningDesc = desc;
        this.quizIsShowing = isQuiz;
        this.isQuizExist = isQuizExist;
        this.forceUpdate();
    };
    toggleFeedBack = (value) => {
        this.feedBackModalIsShowing = value;
        this.forceUpdate();
    };
    toggleFeedBackResultModal = (value) => {
        this.feedBackResultModal = value;
        this.feedBackModalIsShowing = this.feedBackResultModal === false ? false : this.feedBackModalIsShowing;
        this.forceUpdate();
    };
    changeKeyModal = (key, value) => {
        this[key] = value;
        this.forceUpdate();
    };
    startEducation = (participantDetail) => {
        this.participantDetail = participantDetail;
        this.getPageData(this.props);
    };
    endSession = () => {
        this.setState({
            isEducationStarted: false
        })
    };
    changeFocused = (value) => {
        this.focusedId = value;
        this.forceUpdate();
    };

    componentWillUnmount() {
        window.onbeforeunload = () => {
        };
        window.onunload = () => {
        };
    }

    render() {
        return (
            this.state.isEducationStarted ?
                <div className={"mainRoot "+(!this.props.isLogin?"detail":"")}>


                    {/*<Prompt
                     when={this.state.isBlocking}
                     message={location =>
                     `Are you sure you want to go to ${location.pathname}`
                     }
                     />*/}

                    <EducationDetailHeaderContainer toggleShareModal={this.toggleShareModal} roleId={null}
                                                    isOnline={true}
                                                    toggleIsEditing={this.toggleIsEditing} isQuizExist={this.props.isQuizExist}
                                                    changeKeyModal={this.changeKeyModal} endSession={this.endSession}
                                                    toggleFeedBack={this.toggleFeedBack} isEditing={false}
                                                    urlLink={this.props.match.params.educationLink}
                                                    ref={this.headerComponent} history={this.props.history}
                                                    detailInfo={this.props.detailInfo}
                                                    isStarted={true} sessionId={this.sessionId}
                                                    detailContent={this.detailContent} openModal={this.toggleModal}
                                                    detailContentComponent={this.detailContentComponent}
                                                    />
                    <div className="detail-content-root" ref={this.detailContent}>
                        <EducationDetailContainer urlLink={this.props.match.params.educationLink}
                                                  history={this.props.history} headerComponent={this.headerComponent}
                                                  focusedId={this.focusedId}
                                                  ref={this.detailContentComponent} changeFocused={this.changeFocused}
                                                  startEducation={this.startEducation} sessionId={this.sessionId}
                                                  detailContent={this.detailContent}
                                                  isStarted={true}/>
                    </div>
                    {
                        this.educationWarningIsShowing === true &&
                        <EducationWarningModalComponent urlLink={this.props.match.params.educationLink}
                                                        toggleModal={this.toggleModal} sessionId={this.sessionId}
                                                        message={this.educationWarningMessage}
                                                        isQuizExist={this.isQuizExist}
                                                        endSession={this.endSession} focusedId={this.focusedId}
                                                        quizIsShowing={this.quizIsShowing}
                                                        desc={this.educationWarningDesc}/>
                    }
                    {
                        this.feedBackModalIsShowing === true &&
                        <FeedBackModalComponent toggleModal={this.toggleFeedBack}
                                                urlLink={this.props.match.params.educationLink}
                                                sessionId={this.sessionId}
                                                toggleFeedBackResultModal={this.toggleFeedBackResultModal}/>
                    }
                    {
                        this.feedBackResultModal === true &&
                        <FeedBackResultModalComponent toggleFeedBackResultModal={this.toggleFeedBackResultModal}/>
                    }
                </div>
                :
                <div>
                    {
                        this.state.isEducationStarted !== null &&
                        <PhoneNumberModalContainer startEducation={this.startEducation} session_id={this.props.match.params.session_id}/>
                    }
                </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.location.pathname.split('-');
    return ({
        isSessionExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isSessionExist : null,
        participants: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].sessionParticipants : [],
        activeBreak: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].activeBreak : [],
        detailInfo: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].info : null,
        roleId: state.sessionReducer.role_id,
        isEditExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isEditExist : null,
        isQuizExist: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].isQuizExist : null,
        isLogin: state.sessionReducer.isLogin
    });
};

export default connect(mapStateToProps)(OnlineEducationDetailPage);
