import React,{Component} from "react";
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import Helper from "../../helper";
import moment from "moment";
import {Link} from "react-router-dom";

class EducationParticipantItemComponent extends Component{
    render() {
        let {pathname,id,name_surname,participant_id,totalSessionCount,percent,endDate,startDate,endTime,startTime} = this.props;
        return(
            <Link
                to={`${pathname}/${participant_id}?endDate=${endDate}${startDate?"&startDate="+startDate:""}`} className="table-item">
                <div className="td-item t-1">
                    <i className="icon-doc"></i>
                    <p>{name_surname}</p>
                </div>
                <div className="td-item t-1">
                    <p>{totalSessionCount}</p>
                </div>
                <div className="td-item t-1">
                    <p>%{Math.round(percent)}</p>
                </div>
                <div className="td-item t-1">
                    <p>{Math.floor((endTime-startTime)/60/60)<10?"0"+Math.floor((endTime-startTime)/60/60):Math.floor((endTime-startTime)/60/60)}:{Math.floor(((endTime-startTime)/60)%60)<10?"0"+Math.floor(((endTime-startTime)/60)%60):Math.floor(((endTime-startTime)/60)%60)}</p>
                </div>
            </Link>
        )
    }
}

export default EducationParticipantItemComponent;