import React, {Component} from "react";
import InfiniteScroll from "react-infinite-scroller";
import PanelDocItemComponent from "../Panel/panelDocItemComponent";
import PanelParticipantsItemComponent from "../Panel/panelParticipantsItemComponent";
import {connect} from "react-redux";
import {getParticipantsByEducationIdAction} from "../../actions/reportAction"
import XLSX from "xlsx";
import Helper from "../../helper";
import DatePickerInputComponent from "./datepickerComponent";
import moment from "moment";
import queryString from 'query-string'
import {getPanelLocationAction} from "../../actions/panelAction";

class ParticipantsByEducationComponent extends Component {
    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search);
        if(values.startDate){
            this.startDate = values.startDate;
        }
        if(values.endDate){
            this.endDate = values.endDate;
        }
        this.getLocations();
        this.loadFunc();

    }

    activePage = 0;
    order = "education.name";
    order_type = "asc";
    isMore = true;
    educationId = this.props.match.params.subPageId;
    startDate;
    endDate = moment().format('YYYY-MM-DD');
    locationId;
    loadFunc = () => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            is_started: true,
            is_finished: 1,
            isReport: true,
            education_id: this.educationId,
            startDate:this.startDate,
            endDate:this.endDate,
            locationId: this.locationId
        };
        this.isMore = false;
        this.forceUpdate();
        this.props.dispatch(getParticipantsByEducationIdAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    downloadResultAsExcel = () => {
        let {items} = this.props;
        if (items && items[0]) {
            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Katılımcı Listesi",
            };
            wb.SheetNames.push("Katılımcı Listesi");
            let sheetItems = [];
            items.map(item => {
                sheetItems.push([item.participant_name_surname, item.session_start_date, item.user_name_surname,item.location_name?item.location_name:"Online",item.totalQuestionCount>0?item.percent:"-"])
            })
            var ws_data = [["Eğitim Adı",items[0].education_name],['Katılımcı Ad Soyad', 'Eğitim Tarihi', 'Eğitmen Ad Soyad', 'Lokasyon', 'Quiz Sonucu'], ...sheetItems];
            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.Sheets["Katılımcı Listesi"] = ws;
            var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
            var buf = new ArrayBuffer(wbout.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
            Helper.saveFile(new Blob([buf], {type: "application/octet-stream"}), items[0].education_name.split(" ").join("-") + "-katilimci-listesi");
        }
    };
    changeEndDate = (endDate) => {
        this.endDate = endDate;
        this.activePage = 0;
        this.loadFunc();
    }
    changeStartDate = (startDate) => {
        this.startDate = startDate;
        this.activePage = 0;
        this.loadFunc();
    }
    locationOnChange = (e) => {
        this.locationId = e.target.value;
        this.activePage = 0;
        this.loadFunc();
    }
    getLocations = () => {
        this.props.dispatch(getPanelLocationAction({
            activePage: 0,
            order: "name",
            order_type: "asc"}, (res, isMore) => {
        }));
    }
    render() {
        let {items,locations} = this.props;
        return (
            <div className="panel-content-block">

                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head table-filter">
                         <div></div>
                            {(items && items[0]) && <button className="btn-2" onClick={this.downloadResultAsExcel}>Excel İndir</button>}
                        </div>
                        <div className="table-head table-filter">
                            <div className="select-container report">
                                <div className="select-block">
                                    {
                                        locations &&
                                        <select onChange={this.locationOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                locations.map((locationItem, key) =>
                                                    <option key={key} value={locationItem.id}>{locationItem.name}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>
                            <DatePickerInputComponent changeStartDate={this.changeStartDate} startDate={this.startDate} endDate={this.endDate}
                                                      changeEndDate={this.changeEndDate}/>
                        </div>

                        <div className="table-head">
                            <div className="t-1 td-item">
                                <p>TC</p>
                                {/* <i className="icon-arr-bottom"></i> */}
                            </div>
                            <div className="t-1 td-item ">
                                <p>Katılımcı Ad Soyad</p>
                            </div>
                            <div className="t-1 td-item ">
                                <p>Eğitim Tarihi</p>
                            </div>
                            <div className="t-1 td-item ">
                                <p>Eğitmen Ad Soyad</p>
                            </div>
                            <div className="t-1 td-item ">
                                <p>Lokasyon</p>
                            </div>
                            <div className="t-1 td-item ">
                                <p>Quiz Sonucu</p>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item, key) =>
                                            <PanelParticipantsItemComponent report={true} startDate={this.startDate} endDate={this.endDate}
                                                                            key={key} {...this.props} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.participantsEducations,
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(ParticipantsByEducationComponent);
