import React, {Component} from 'react';
import {EDUCATIONDETAIL_WITHOUTPARAM, PANEL_DOCUMENTS_WITOUTPARAM} from '../../constants/routePaths';
import moment from 'moment';
import trLocale from 'moment/locale/tr';

moment.locale('tr', trLocale);

class PanelParticipantsItemComponent extends Component {

    render() {
        let {location_name, tc_no, participant_name_surname, session_start_date, user_name_surname, report, correctCount, totalQuestionCount, percent} = this.props;
        return (
            participant_name_surname ?
                <div className="table-item">
                    <div className="td-item t-1">
                        <p>{tc_no}</p>
                    </div>
                    <div className="td-item t-1">
                        <p>{participant_name_surname}</p>
                    </div>
                    <div className="td-item t-1">
                        <p>{session_start_date}</p>
                    </div>
                    <div className="td-item t-1">
                        <p>{user_name_surname}</p>
                    </div>
                    <div className="td-item t-1">
                        <p>{location_name?location_name:"Online"}</p>
                    </div>
                    {
                        report &&
                        <div className="td-item t-1">
                            <p>{totalQuestionCount > 0 ? percent : "-"}</p>
                        </div>
                    }
                </div>
                :
                <div></div>
        )
    }
}

export default PanelParticipantsItemComponent;