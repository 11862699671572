import React,{Component} from 'react';
import moment from "moment";

class QuizParticipantsContainer extends Component{
    calculateDateDiff = (firstDate,secondDate) => {
        let firstDateMoment = moment(firstDate);
        let secondDateMoment = moment(secondDate);
        let hours = firstDateMoment.diff(secondDateMoment,'hours')
        let minutes = firstDateMoment.diff(secondDateMoment,'minutes')%60
        let seconds = firstDateMoment.diff(secondDateMoment,'seconds')%60
        return `${hours && hours>0?(hours<10?"0":"")+hours:"00"}:${minutes && minutes>0?(minutes<10?"0":"")+minutes:"00"}:${seconds && seconds>0?(seconds<10?"0":"")+seconds:""}`

    }
    render(){
        let {participants, sessionDetail} = this.props;
        return(
            <div className="table-root">
                <p className="head-text">Katılımcılar</p>
                <div className="table-container">
                    <div className="table-head">
                        <div className="t-1 td-item">
                            <p>Kullanıcı Adı</p>
                            <i className="icon-arr-bottom"></i>
                        </div>
                        {
                            (sessionDetail && sessionDetail.session_type === "online" && !sessionDetail.session_end_date) &&
                            <div className="t-1 td-item">
                                <p>Eğitim Durumu</p>
                                {/*<i className="icon-arr-bottom"></i>*/}
                            </div>
                        }
                        {
                            (sessionDetail && sessionDetail.session_type === "online" && !sessionDetail.session_end_date) &&
                            <div className="t-1 td-item">
                                <p>Quiz Durumu</p>
                                {/*<i className="icon-arr-bottom"></i>*/}
                            </div>
                        }
                        <div className="t-2 td-item">
                            <p>T.C. Kimlik No</p>
                            <i className="icon-arr-bottom"></i>
                        </div>
                    </div>
                    <div className="table-block">
                        {
                            participants && participants.map((item,key)=>
                                <div className="table-item" key={key}>
                                    <div className="td-item t-1">
                                        <i className={"icon-user2"}></i>
                                        <p>{item.participant.name_surname}</p>
                                    </div>
                                    {
                                        (sessionDetail.session_type === "online" && !sessionDetail.session_end_date) &&
                                        <div className="td-item t-1">
                                            <i className={"icon-user2"}></i>
                                            <p>{item.started_date?"Tamamlandı":(!item.sms_code ?"Başlamadı":"Başladı")}</p>
                                        </div>
                                    }
                                    {
                                        (sessionDetail.session_type === "online" && !sessionDetail.session_end_date) &&
                                        <div className="td-item t-1">
                                            <i className={"icon-user2"}></i>
                                            <p>{item.end_date?"Tamamlandı":(item.started_date ?"Başladı":"Başlamadı")}</p>
                                        </div>
                                    }
                                    <div className="td-item t-2">
                                        <p>{item.participant.tc_no}</p>
                                    </div>

                                </div>
                            )
                        }

                    </div>

                </div>
            </div>
        )
    }
};

export default QuizParticipantsContainer;