import React, {Component} from 'react';
import InfiniteScroll from "react-infinite-scroller";
import PanelDocItemComponent from "../Panel/panelDocItemComponent";
import {getPanelLocationAction, getPanelUserAction, getStartedEducationList} from "../../actions/panelAction";
import {connect} from "react-redux";
import XLSX from "xlsx";
import Helper from "../../helper";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';import 'moment/locale/tr'
import DatePickerInputComponent from "./datepickerComponent";
moment.locale('tr-TR');
setDefaultLocale('tr');

class EducationListComponent extends Component {
    activePage = 0;
    order = "education.name";
    order_type = "asc";
    isMore = true;
    categoryId = "0";
    inputText = React.createRef();
    userId = "0";
    years = [];
    activeYear = "0"
    startDate;
    locationId;
    endDate = moment().format('YYYY-MM-DD');
    constructor(props) {
        super(props);
        this.getUsers();
        this.loadFunc();
        this.getLocations();
        let lastYear = new Date().getFullYear();
        let startYear = 2018;
        while (lastYear>=startYear){
            this.years.push(startYear);
            startYear+=1;
        }
    }

    getLocations = () => {
        this.props.dispatch(getPanelLocationAction({
            activePage: 0,
            order: "name",
            order_type: "asc"}, (res, isMore) => {
        }));
    }

    loadFunc = (isFull,cb) => {
        let params = {
            activePage: this.activePage,
            order: this.order,
            order_type: this.order_type,
            is_started: true,
            is_finished: 1,
            isReport: true,
            categoryId: this.categoryId,
            searchTerm: this.inputText.current ? this.inputText.current.value : "",
            userId:this.userId,
            isFull: isFull===true,
            activeYear: this.activeYear,
            startDate:this.startDate,
            endDate:this.endDate,
            locationId: this.locationId
        };
        this.isMore = false;
        this.forceUpdate();
        this.props.dispatch(getStartedEducationList(params, (res, isMore) => {
            if(isFull === true){
                cb(res);
                this.isMore = true;
                this.forceUpdate();
            } else if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };

    getUsers = () => {
        let params = {
            activePage: 0,
            order: "name_surname",
            order_type: this.order_type
        };
        this.props.dispatch(getPanelUserAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    }
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };
    categoryOnChange = (e) => {
        this.activePage = 0;
        this.categoryId = e.target.value;
        this.loadFunc();
    }
    onKeyUp = (e) => {
        this.activePage = 0;
        this.loadFunc();
    }
    userOnChange = (e) => {
        this.activePage = 0;
        this.userId = e.target.value;
        this.loadFunc();
    }
    downloadResultAsExcel = () => {
        this.loadFunc(true,(items)=>{
            if (items && items[0]) {
                var wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Eğitim Listesi",
                };
                wb.SheetNames.push("Eğitim Listesi");
                let sheetItems = [];
                items.map(item => {
                    sheetItems.push([item.education_name, item.sessionCount,item.totalParticipantCount ,"%"+Math.round(item.percent)])
                })
                var ws_data = [['Eğitim adı', 'Eğitim Sayısı', 'Toplam Katılımcı Sayısı', 'Katılımcı Başarı Oranı'], ...sheetItems];
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets["Eğitim Listesi"] = ws;
                var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
                var buf = new ArrayBuffer(wbout.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
                Helper.saveFile(new Blob([buf], {type: "application/octet-stream"}), `egitim_listesi`);
            }
        })
    };
    yearOnChange = (e) => {
        this.activePage = 0;
        this.activeYear = e.target.value;
        this.loadFunc();
    }
    changeEndDate = (endDate) => {
        this.endDate = endDate;
        this.activePage = 0;
        this.loadFunc();
    }
    changeStartDate = (startDate) => {
        this.startDate = startDate;
        this.activePage = 0;
        this.loadFunc();
    }
    locationOnChange = (e) => {
        this.locationId = e.target.value;
        this.activePage = 0;
        this.loadFunc();
    }
    render() {
        let {items, categories, users, locations} = this.props;

        return (
            <div className="panel-content-block ">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head table-filter">
                            <div className="input-item">
                                <label>
                                    <input type="text" ref={this.inputText} onKeyUp={this.onKeyUp}/>
                                </label>
                            </div>
                            {(items && items[0]) && <button className="btn-2" onClick={this.downloadResultAsExcel}>Excel İndir</button>}
                        </div>
                        <div className="table-head table-filter">
                            <div className="select-container report">
                                <div className="select-block">
                                    {
                                        categories &&
                                        <select onChange={this.categoryOnChange}>
                                            <option value='0'>Kategori Seçiniz</option>
                                            {
                                                categories.map((categoryItem, key) =>
                                                    <option key={key} value={categoryItem.id}>{categoryItem.name}</option>
                                                )
                                            }
                                        </select>

                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                                <div className="select-block">
                                    {
                                        users &&
                                        <select onChange={this.userOnChange}>
                                            <option value='0'>Eğitmen Seçiniz</option>
                                            {
                                                users.map((userItem, key) =>
                                                    <option key={key} value={userItem.id}>{userItem.name_surname}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>

                                {/*<div className="select-block">
                                    {
                                        this.years &&
                                        <select onChange={this.yearOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                this.years.map((yearItem, key) =>
                                                    <option key={key} value={yearItem}>{yearItem}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>*/}
                                <div className="select-block">
                                    {
                                        locations &&
                                        <select onChange={this.locationOnChange}>
                                            <option value='0'>Seçiniz</option>
                                            {
                                                locations.map((locationItem, key) =>
                                                    <option key={key} value={locationItem.id}>{locationItem.name}</option>
                                                )
                                            }
                                        </select>
                                    }
                                    <i className="icon-arr-bottom"></i>
                                </div>
                            </div>

                            <DatePickerInputComponent changeStartDate={this.changeStartDate}
                                                      changeEndDate={this.changeEndDate}/>
                        </div>

                        <div className="table-head">
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("education.name")
                            }}>
                                <p>Eğitim adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("sessionCount")
                            }}>
                                <p>Eğitim Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("totalParticipantCount")
                            }}>
                                <p>Toplam Katılımcı Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={() => {

                            }}>
                                <p>Katılımcı Başarı Oranı</p>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item, key) =>
                                            <PanelDocItemComponent isReport={true} key={key} startDate={this.startDate}
                                                                   pathname={this.props.location.pathname} endDate={this.endDate}
                                                                   history={this.props.history} {...this.props} {...item}/>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.educations,
    categories: state.educationCategoriesReducer,
    users: state.PanelReducer.users,
    locations: state.PanelReducer.locations
});

export default connect(mapStateToProps)(EducationListComponent);
