import React, {Component} from 'react';
import Helper from "../../helper";
import QuizDurationComponent from "./quizDurationComponent";
import moment from "moment";

const sortResult = (a, b) => {
    return b.correct_count - a.correct_count;
};

class QuizParticipantsResultComponent extends Component {
    calculateDateDiff = (firstDate,secondDate) => {
        let firstDateMoment = moment(firstDate);
        let secondDateMoment = moment(secondDate);
        let hours = firstDateMoment.diff(secondDateMoment,'hours')
        let minutes = firstDateMoment.diff(secondDateMoment,'minutes')%60
        let seconds = firstDateMoment.diff(secondDateMoment,'seconds')%60
        return `${hours && hours>0?(hours<10?"0":"")+hours:"00"}:${minutes && minutes>0?(minutes<10?"0":"")+minutes:"00"}:${seconds && seconds>0?(seconds<10?"0":"")+seconds:""}`

    }
    render() {
        let {quizResults, sessionDetail, participants, quizInfo} = this.props;
        let bests = quizResults ? quizResults.sort(sortResult).filter(item => item.correct_count / item.total_count >= 0.7):[];
        let pasts = quizResults ? quizResults.sort(sortResult).filter(item => item.correct_count / item.total_count < 0.7 && item.correct_count / item.total_count >= 0.5):[];
        let fails = quizResults ? quizResults.sort(sortResult).filter(item => item.correct_count / item.total_count < 0.5):[];
        return (
            <div className="participants-result-root">
                <div className="status-root">
                    <div className="text-block">
                        <div className="left-text-item">
                            <h2>Özet</h2>
                        </div>

                    </div>
                    <div className="status-block">
                        <div className="status-item">
                            <div className="status-text-item">
                                <p>Eğitim Süresi</p>
                                {
                                    quizInfo &&
                                    <p>{moment.utc(moment(quizInfo.end_date).diff(moment(quizInfo.started_date))).format("HH:mm:ss")}</p>
                                }
                            </div>
                            <div className="status-bar">
                                <div className="progress-item" style={{width:"100%"}}></div>
                            </div>
                        </div>
                        <div className="status-item green-progress">
                            <div className="status-text-item">
                                <p>Doğru Cevaplar</p>
                                {
                                    quizResults ?
                                        <p className="green">%{((pasts.length*100) / quizResults.length).toFixed(2)} Başarı</p>
                                        :
                                        <p className="green">%0 Başarı</p>
                                }
                                <p>Yanlış Cevaplar</p>
                            </div>
                            {
                                quizResults &&
                                <div className="status-bar">
                                    <div className="progress-item" style={{width:((pasts.length*100) / quizResults.length)+"%"}}></div>
                                    <div className="progress-item red" style={{width:((pasts.length*100) / quizResults.length)-100+"%"}}></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="participants-root">
                    <div className="head-block">
                        <p className="head-text">Katılımcılar</p>
                        <span>Toplam Katılımıcı : <span>{quizResults?quizResults.length:0}</span></span>
                    </div>

                    <div className="participants-container">
                        {
                            (bests && bests.length > 0) &&
                            <div className="participants-info-container">
                                <div className="head-item">
                                    <span className="green">En İyiler</span>
                                </div>
                                {
                                    bests.map((resultItem, key) =>
                                        <div className="participants-block" key={key}>
                                            <div className="participants-tab-item">
                                                <p>{resultItem.info.participant.name_surname}</p>
                                                <p>{resultItem.info.participant.tc_no}</p>
                                            </div>
                                            <div className="participants-info-block">
                                                <div className="participants-item">
                                                    <p>Eğitim Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Eğitim Süresi</p>
                                                    <span>{resultItem.info.education_start_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.started_date, resultItem.info.education_start_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Süresi</p>
                                                    <span>{resultItem.info.end_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.end_date, resultItem.info.started_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Doğru Sayısı</p>
                                                    <span>{resultItem.info.end_date ? resultItem.correct_count : "0"}</span>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }

                            </div>
                        }
                        {
                            (pasts && pasts.length>0) &&
                            <div className="participants-info-container">
                                <div className="head-item">
                                    <span className="grey">Geçenler</span>
                                </div>
                                {
                                    pasts.map((resultItem, key) =>
                                        <div className="participants-block" key={key}>
                                            <div className="participants-tab-item">
                                                <p>{resultItem.info.participant.name_surname}</p>
                                                <p>{resultItem.info.participant.tc_no}</p>
                                            </div>
                                            <div className="participants-info-block">
                                                <div className="participants-item">
                                                    <p>Eğitim Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Eğitim Süresi</p>
                                                    <span>{resultItem.info.education_start_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.started_date, resultItem.info.education_start_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Süresi</p>
                                                    <span>{resultItem.info.end_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.end_date, resultItem.info.started_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Doğru Sayısı</p>
                                                    <span>{resultItem.info.end_date ? resultItem.correct_count : "0"}</span>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            (fails && fails.length > 0) &&
                            <div className="participants-info-container">
                                <div className="head-item">
                                    <span className="red">Kalanlar</span>
                                </div>
                                {
                                    fails.map((resultItem, key) =>
                                        <div className="participants-block" key={key}>
                                            <div className="participants-tab-item">
                                                <p>{resultItem.info.participant.name_surname}</p>
                                                <p>{resultItem.info.participant.tc_no}</p>
                                            </div>
                                            <div className="participants-info-block">
                                                <div className="participants-item">
                                                    <p>Eğitim Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Eğitim Süresi</p>
                                                    <span>{resultItem.info.education_start_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.started_date, resultItem.info.education_start_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Durumu</p>
                                                    <span>Bitti</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Quiz Süresi</p>
                                                    <span>{resultItem.info.end_date && resultItem.info.started_date ? this.calculateDateDiff(resultItem.info.end_date, resultItem.info.started_date) : "-"}</span>
                                                </div>
                                                <div className="participants-item">
                                                    <p>Doğru Sayısı</p>
                                                    <span>{resultItem.info.end_date ? resultItem.correct_count : "0"}</span>
                                                </div>

                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default QuizParticipantsResultComponent;