import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import {PANEL_REPORTS_WITOUTPARAM} from "../../constants/routePaths";
import {
    getDepartmentQuizResultAction, getEducationQuizResultAction,
    getEducationSessionByYearAction, getEducationSessionDepartmentByYearAction,
    getEducationSessionUsersByYearAction, getQuizResultByEducationAction, getWeeklyEducationReportAction,getParticipantsByEducationIdAction
} from "../../actions/reportAction";
import {Chart} from 'react-google-charts';
import EducationForMonthComponent from "../../components/report/educationForMonthComponent";
import EducationForUsersComponent from "../../components/report/educationForUsersComponent";
import EducationForDepartmentComponent from "../../components/report/educationForDepartmentComponent";
import QuizResultDepartmentComponent from "../../components/report/quizResultDepartmentComponent";
import QuizResultEducationComponent from "../../components/report/QuizResultEducationComponent";
import QuizResultByEducationComponent from "../../components/report/QuizResultByEducationComponent";
import EducationForWeekComponent from "../../components/report/educationForWeekComponent";
import {getPanelLocationAction} from "../../actions/panelAction";
import EducationListComponent from "../../components/report/educationListComponent";
import ParticipantsByEducationComponent from "../../components/report/participantsByEducationComponent";
import ParticipantListComponent from "../../components/report/participantsListComponent";
import EducationByParticipantListComponent from "../../components/report/educationByParticipantListComponent";
import ReportUserListContainer from "../../components/report/ReportUserListContainer";
import EducationsByUserComponent from "../../components/report/educationsByUserComponent";

class PanelReportContainer extends Component {
    chartData;
    chartType;

    constructor(props) {
        super(props);
        this.getData(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.chartData = null;
            this.getData(this.props)
        }
    }

    getData = (props, year, education_id, month, sortName, sortType, selectedWeek, selectedLocation) => {
        console.log(this.props);
        switch (props.location.pathname) {
            case PANEL_REPORTS_WITOUTPARAM + "/egitimler":
                this.chartType = "education_by_month";
                this.props.dispatch(getEducationSessionByYearAction({year: year ? year : new Date().getFullYear()}, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitimen-egitim":
                this.chartType = "education_by_user";
                this.props.dispatch(getEducationSessionUsersByYearAction({year: year ? year : new Date().getFullYear()}, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/departman-egitim":
                this.chartType = "education_by_department";
                this.props.dispatch(getEducationSessionDepartmentByYearAction({year: year ? year : new Date().getFullYear()}, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/departman-test":
                this.chartType = "department_test";
                this.props.dispatch(getDepartmentQuizResultAction({year: year ? year : new Date().getFullYear()}, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitim-test":
                this.chartType = "education_test";
                this.props.dispatch(getEducationQuizResultAction({year: year ? year : new Date().getFullYear()}, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/katilimci-basari-siralamasi":
                this.chartType = "quiz_results";
                this.props.dispatch(getQuizResultByEducationAction({
                    education_id: education_id,
                    year: year ? year : new Date().getFullYear(),
                    month: month,
                    sortName,
                    sortType
                }, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/haftalik-egitim-raporu":
                this.chartType = "education_by_week";
                let dt = new Date();
                var currentWeekDay = dt.getDay();
                var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
                var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
                var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
                let params = {
                    activePage: 0,
                    order: "created_date",
                    order_type: "desc"
                };
                this.props.dispatch(getPanelLocationAction(params, () => {
                }));
                this.props.dispatch(getWeeklyEducationReportAction({
                    education_id: education_id ? education_id : 0,
                    year: year ? year : new Date().getFullYear(),
                    month: month ? month : 0,
                    sortName,
                    sortType,
                    selectedWeek: selectedWeek ? selectedWeek : this.props.selectWeek ? this.props.selectWeek : [wkStart, wkEnd],
                    selectedLocation
                }, (data) => {
                    this.chartData = data;
                    this.forceUpdate();
                }));
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/tamamlanmis-egitimler":
                this.chartType = "educations";
                this.chartData = {};
                this.forceUpdate();
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/tamamlanmis-egitimler/" + (this.props.match.params.subPageId):
                this.chartType = "participant_by_educations";
                this.chartData = {};
                this.forceUpdate();
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitime-katilan-kullanicilar":
                this.chartType = "participants";
                this.chartData = {};
                this.forceUpdate();
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitime-katilan-kullanicilar/"+ (this.props.match.params.subPageId):
                this.chartType = "educations_by_participant";
                this.chartData = {};
                this.forceUpdate();
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitmenler":
                this.chartType = "users";
                this.chartData = {};
                this.forceUpdate();
                break;
            case PANEL_REPORTS_WITOUTPARAM + "/egitmenler/"+ (this.props.match.params.subPageId):
                this.chartType = "educations_by_users";
                this.chartData = {};
                this.forceUpdate();
                break;
            default:

        }
    };

    getRenderedChart = () => {
        switch (this.chartType) {
            case "education_by_month":
                return <EducationForMonthComponent {...this.props} getData={this.getData} chartData={this.chartData}/>;
            case "education_by_user":
                return <EducationForUsersComponent {...this.props} getData={this.getData} chartData={this.chartData}/>;
            case "education_by_department":
                return <EducationForDepartmentComponent {...this.props} getData={this.getData}
                                                        chartData={this.chartData}/>;
            case "department_test":
                return <QuizResultDepartmentComponent {...this.props} getData={this.getData}
                                                      chartData={this.chartData}/>;
            case "education_test":
                return <QuizResultEducationComponent {...this.props} getData={this.getData}
                                                     chartData={this.chartData}/>;
            case "quiz_results":
                return <QuizResultByEducationComponent {...this.props} getData={this.getData} data={this.chartData}/>;
            case "education_by_week":
                return <EducationForWeekComponent {...this.props} getData={this.getData}/>;
            case "educations":
                return <EducationListComponent {...this.props} getData={this.getData}/>
            case "participant_by_educations":
                return <ParticipantsByEducationComponent {...this.props} getData={this.getData}/>
            case "participants":
                return <ParticipantListComponent {...this.props} getData={this.getData}/>
            case "educations_by_participant":
                return <EducationByParticipantListComponent {...this.props} getData={this.getData}/>
            case "users":
                return <ReportUserListContainer {...this.props} getData={this.getData}/>
            case "educations_by_users":
                return <EducationsByUserComponent {...this.props} getData={this.getData}/>
            default:
                return "";
        }
    };

    render() {
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Raporlar"/>
                <div className="table-root">
                    <div className="table-container">
                        {
                            this.chartData &&
                            <Fragment>
                                {this.getRenderedChart()}
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectWeek: state.PanelReducer.selectWeek
});

export default connect(mapStateToProps)(PanelReportContainer);